import React, { useEffect } from 'react';
import { fetchWithDefaults } from '../../config/fetchDefault';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';

function Payment({ formData, imoInfo, setLoading, submitToStripe, setSubmitToStripe, stripePriceId}) {
    const stripe = useStripe();
    const elements = useElements();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const createCustomer = () => {

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        setLoading(true);
        stripe.confirmSetup({
            elements,
            redirect: 'if_required',
            confirmParams: {
                // Return URL where the customer should be redirected after the SetupIntent is confirmed.
                //return_url: 'https://example.com',
            },
        })
        .then(async function (results) {
            if (results.error) {
                setLoading(false);
                setSubmitToStripe(false)
                console.log(results);
                // Inform the customer that there was an error.
            }
            else {
                if (results.setupIntent.status === 'succeeded') {
                    try {
                        const requestOptions = {
                            method: 'POST',
                            body: JSON.stringify({
                                form: {
                                    ...formData,
                                    AgentCode: imoInfo.AgentCode != null ? imoInfo.AgentCode : '',
                                    UplineAgentCode: imoInfo.UplineAgentCode != null ? imoInfo.UplineAgentCode : '',
                                    NPN: imoInfo.NPN != null ? imoInfo.NPN : '',
                                    LeadershipLevel: imoInfo.LeadershipLevel != null ? imoInfo.LeadershipLevel : '',
                                },
                                method: results.setupIntent.payment_method,
                                imoInfo: imoInfo,
                                organization: urlParams.get('organization'),
                                priceId: stripePriceId
                            })
                        };

                        const response = await fetchWithDefaults('/registration/createCustomer', requestOptions);
                        if (!response.ok) {
                            throw new Error("Network response was not ok");
                        }
                        const jsonData = await response.json();
                        if (jsonData === true) {
                            window.location.href = window.location.origin;
                        }

                        // adding this here incase the user isn't redirected
                        setLoading(false);
                        setSubmitToStripe(false)
                    } catch (error) {
                        setLoading(false);
                        setSubmitToStripe(false)
                        console.error("Error fetching data:", error);
                    }
                }
            }
        })
        .catch((error) => {
            setLoading(false)
            setSubmitToStripe(false)
            console.error('Error creataing customer:', error)
        })
    }

    useEffect(() => {
        // check if we need to trigger subscription call
        if(submitToStripe === true){
            createCustomer()
        }
    }, [submitToStripe])
    return (
        <span style={{alignSelf:'stretch'}}>
            <div>
                <PaymentElement />
            </div>
        </span>
    );
}

export default Payment;