import React, {useState, useEffect, useRef} from 'react';
import '../../css/signup.css';
import image from '../../assets/icons/QuilitySwitchboardFunnel.svg';
import { fetchWithDefaults } from '../../config/fetchDefault';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import PaymentPreUpdates from './paymentPreUpdates';
import image2 from '../../assets/icons/loading-loader.gif';

/**
 * THIS BASED ON IS THE MOST RECENT COMMIT OF FOR SIGNUP COMPONENT BEFORE DEVELOPMENT BEGAN ON FUN-156
 */

const stripePromise = loadStripe('pk_test_RGmoYxMaWO1N2K3JqJRTAFPQ');
function SignupPreUpdates({}) {
    const [terms, setTerms] = useState(false);
    const formItems=['Work_Email']
    const secondItems=['Username','First_Name', 'Last_Name', 'Phone_Number', 'Password', 'Confirm_Password','Time_Zone'];
    const paymentFields=["Name_on_Card", "Address", "City", "State"];
    const [focusedField, setFocusedField] = useState('');
    const [searched, setSearched] = useState(false);
    const [imoInfo, setImoInfo] = useState(false);
    const [noExist, setNoExist] = useState(false);
    const [secret, setSecret] = useState(false);
    const [loading, setLoading] = useState(false);
    const [usernameCheck, setUsernameCheck] = useState(null);
    const [alreadyExists, setAlreadyExists] = useState(false);
    const [errors, setErrors] = useState({
        "passwordLength": "At least 10 characters long.",
        "passwordCapital": "At least one uppercase letter.",
        "passwordNumber": "At least one number.",
        "passwordSpecial": "At least one special character.",
        "passwordMatch": "Passwords do not match."
    });
    const options = {
        // passing the client secret obtained from the server
        clientSecret: secret,
    };
    const replaceUnderscore = (item) => {
        const newText = item.replaceAll('_', ' ');
        return newText;
    };
    const searchImo= async()=>{
        setLoading(true)
        setAlreadyExists(false);
        setNoExist(false);
        const workEmail = formData['Work_Email'];
        try {
            const response = await fetchWithDefaults(`/registration/lookupImo?email=${workEmail}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setLoading(false)
            if(jsonData.status !== false){
                setImoInfo(jsonData[0]);
                handleInputChange('First_Name', jsonData[0].FirstName);
                handleInputChange('Last_Name', jsonData[0].LastName);
                handleInputChange('Phone_Number', jsonData[0].AgentPhone);
                handleInputChange('Name_on_Card', jsonData[0].AgentName);
                handleInputChange('Address', jsonData[0].StreetAddress);
                handleInputChange('City', jsonData[0].City);
                handleInputChange('State', jsonData[0].State);
                handleInputChange('Zip', jsonData[0].Zip);
                if(jsonData[1].exists){
                    setAlreadyExists(true)
                }
                else{
                    setSearched(true);
                }
            }
            else{
                setNoExist(true)
                setSearched(true);
            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error);
        }
    }
    const gatherSecret= async()=>{

        try {
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({
                    amount: 5000,
                })
            };

            const response = await fetchWithDefaults('/registration/getStripeSecret', requestOptions);
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            const jsonData = await response.json();
            setSecret(jsonData.client_secret);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const handleFocus = (item) => {
        setFocusedField(item);
    };

      const handleBlur = () => {
        setFocusedField('');
    };
    const [formData, setFormData] = useState(
        {
            Work_Email: '',
            Username : '',
            First_Name:'',
            Last_Name: '',
            Phone_Number: '',
            Password: '',
            Confirm_Password: '',
            Time_Zone: '',
            Name_on_Card: '',
            Address: '',
            City: '',
            State: '',
            Zip: '',
        }
    );

    const handleInputChange = (item, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [item]: value,
        }));
        if (item === 'Password' || item === 'Confirm_Password') {
            validatePassword(item, value);
        }
    };
    const validatePassword = (field, value) => {
        let errorMessages = {};

        if (field === 'Password') {
            const password = value;
            if (password.length < 10) {
                errorMessages.passwordLength = 'At least 10 characters long.';
            }
            if (!/[A-Z]/.test(password)) {
                errorMessages.passwordCapital = 'At least one uppercase letter.';
            }
            if (!/[0-9]/.test(password)) {
                errorMessages.passwordNumber = 'At least one number.';
            }
            if (!/[!@#$%^&*]/.test(password)) {
                errorMessages.passwordSpecial = 'At least one special character.';
            }
        }

        if (field === 'Confirm_Password' || formData['Password']) {
            if (formData['Password'] !== value) {
                errorMessages.passwordMatch = 'Passwords do not match.';
            }
        }

        setErrors((prevErrors) => ({
            ...errorMessages
        }));
    };
    const handleUsername = async ()=>{
        try {
            const user = formData['Username'];
            setLoading(true);
            const response = await fetchWithDefaults(`/registration/searchUsername?username=${user}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setLoading(false);
            if(jsonData === false){

                setUsernameCheck(false);
            }
            else{
                setUsernameCheck(true);
            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error);
        }
    }

    const phoneRef = useRef()
    const phoneInputMask = () => {
        let initPhoneValue = phoneRef.current.value

        if(phoneRef.current.value.replace(/(\D)/g, '').length > 10){
            initPhoneValue = initPhoneValue.replace(/(\D)/g, '').substring(0,10)
        }

        phoneRef.current.value = initPhoneValue.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/).slice(1).join(' ')

        const unmaskedPhoneNumber = phoneRef.current.value.replace(/(\D)/g, '');
        handleInputChange('Phone_Number', unmaskedPhoneNumber)
    }

    useEffect( () => {
        if(!secret){
            setSecret(true);
        }
        if(secret===true){
            gatherSecret();
        }
        const getTimeZone = () => {
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            handleInputChange('Time_Zone',timeZone);
        };

        getTimeZone();
    },[secret])

    return (
        <div className="completeSignUpWrapper">
            {loading && (
                <div className="loadingScreen">
                    <div className="loaderWrapper"><img src={image2} alt="Loading Gif" style={{width: '100%'}} /></div>
                </div>
            )}

            <div className="signUpWrapper">
                <div className={`frontPageQuilityParentBlockWrapper`} style={{marginTop: '70px'}}>
                <div className="signupLogo">
                    <img src={image} alt="Main Logo" className="signupLogo"/>
                </div>
                    <div className="quilityParentBlockHeader">
                        <div className="quilityParentBlockTitle">Create New Acocunt</div>
                    </div>
                    <div className="quilityParentBlockContent" style={{textAlign:'left'}}>
                        <span className="quilityNestedBlockWrapper" style={{ height: 'fit-content',margin: '20px 0px' }}>
                            <div className="quilityNestedBlockHeader" >
                                <div className="quilityParentBlockTitle">Account Information</div>
                                <span className="quilityBlockIcon">
                                </span>
                            </div>
                            <div className="quilityNestedBlockContent">

                                {formItems.map((item, index) => (
                                    <div className="quilityDataLine" key={`form1${index}`}>
                                        <div className="dataFieldTitle">{replaceUnderscore(item)}</div>
                                        <input
                                            type="text"
                                            className={`qualifierFieldInput ${item==='Work_Email' && 'shorten'}`}
                                            value={formData[item]}
                                            onChange={(e) => handleInputChange(item, e.target.value)}
                                        />
                                        {item==='Work_Email' && (
                                            <div className="searchMe solidQuilityActiveButton" onClick={()=>searchImo()}>Find Me</div>
                                        )}
                                        {noExist && (
                                            <span className="noExist">This Email was not found in your IMO - please manually enter info</span>
                                        )}
                                    </div>
                                ))}
                                {alreadyExists && (
                                    <div className="quilityDataLine">
                                        <span className="existingAgent">An account associated with this agent exists</span>
                                        <div className="goToLogin solidQuilityActiveButton" onClick={()=>searchImo()}>Login To Account</div>
                                    </div>
                                )}
                                {searched && (
                                    secondItems.map((item, index) => (
                                        <div className="quilityDataLine" key={`secondItems${index}`}>
                                            <div className="dataFieldTitle">{replaceUnderscore(item)}</div>
                                            {item!=='Phone_Number' && (<input
                                                type={`${item==='Password' ? 'password' : item==='Confirm_Password' ? 'password' : ''}`}
                                                className="qualifierFieldInput"
                                                value={formData[item]}
                                                onChange={(e) => handleInputChange(item, e.target.value)}
                                                onFocus={item === 'Password' || item === 'Confirm_Password' ? () => handleFocus(item) : undefined}
                                                onBlur={item === 'Password' || item === 'Confirm_Password' ? handleBlur : item==='Username' ? handleUsername : undefined}
                                            />)}

                                            {item === 'Phone_Number' && (
                                                <input
                                                    ref={phoneRef}
                                                    className="qualifierFieldInput"
                                                    onChange={() => phoneInputMask()}
                                                />
                                            )}

                                            {item==='Username' && usernameCheck===false ? (
                                                <span style={{color:'green', marginLeft:'15px', fontWeight:'700', fontSize:'16px'}}>&#10003;</span>
                                            ) : item==='Username' && usernameCheck===true ? (
                                                <span style={{color:'red', marginLeft:'15px', fontWeight:'700', fontSize:'16px'}}>X</span>
                                            ) : (
                                                null
                                            )}
                                            {item==='Username' && usernameCheck===true && (
                                                <span className="noExist">This username is already in use, please choose a new one</span>
                                            )}
                                            {item==='Password' && (
                                                focusedField==='Password' && Object.keys(errors).length > 0 && (
                                                    <div>
                                                        {errors.passwordLength && <div className="error">{errors.passwordLength}</div>}
                                                        {errors.passwordCapital && <div className="error">{errors.passwordCapital}</div>}
                                                        {errors.passwordNumber && <div className="error">{errors.passwordNumber}</div>}
                                                        {errors.passwordSpecial && <div className="error">{errors.passwordSpecial}</div>}
                                                    </div>
                                                )
                                            )}
                                            {item==='Confirm_Password' && Object.keys(errors).length > 0 && (
                                                focusedField==='Confirm_Password' && (
                                                    <div className="passwordErrors">
                                                        {errors.passwordMatch && <div className="error">{errors.passwordMatch}</div>}
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    ))
                                )}
                            </div>
                        </span>
                    </div>

                    {searched && (
                        <div className="quilityParentBlockContent" style={{textAlign:'left'}}>
                            <span className="quilityNestedBlockWrapper" style={{ height: 'fit-content',margin: '20px 0px' }}>
                                <div className="quilityNestedBlockHeader" >
                                    <div className="quilityParentBlockTitle">Payment Information</div>
                                    <span className="quilityBlockIcon">
                                    </span>
                                </div>
                                <div className="quilityNestedBlockContent">
                                    <Elements stripe={stripePromise} options={options}>
                                        {paymentFields.map((item, index) => (
                                            <div className="quilityDataLine" key={`paymentField${index}`}>
                                                <div className="dataFieldTitle">{replaceUnderscore(item)}</div>
                                                <input
                                                    type="text"
                                                    className="qualifierFieldInput"
                                                    value={formData[item]}
                                                    onChange={(e) => handleInputChange(item, e.target.value)}
                                                />
                                            </div>
                                        ))}
                                        <PaymentPreUpdates usernameCheck={usernameCheck} formData={formData} imoInfo={imoInfo} setLoading={setLoading} terms={terms} setTerms={setTerms}/>
                                    </Elements>
                                </div>
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default SignupPreUpdates;