import { fetchWithDefaults } from '../config/fetchDefault';
import { addTag } from '../features/tagSlice';
import { updateSpecificLead } from "../features/queueSlice";

const removeTagFromLead= async (tagId, agentId, leadId)=>{
    try {
        const requestOptions = {
            method: 'DELETE',
            body: JSON.stringify({
                tagId:tagId,
                agent:agentId,
                lead:leadId
            })
        };
        const response = await fetchWithDefaults('/removeTagFromLead', requestOptions);

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        console.log(jsonData)
        if(jsonData.status===false){
            return false;
        }
        else{
            return true;
        }
    }
    catch (error) {
        console.error("Error fetching data:", error);
        return false;
    }
}
const saveTag = async (tag, agentId, leadId, dispatch, add, existingTags = null) => {

    try {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({
                tagName: tag.name,
                tagId:tag.id,
                agent:agentId,
                lead:leadId,
                tag: tag
            })
        };
        const response = await fetchWithDefaults('/addTagToLead', requestOptions);

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        if(jsonData.status===false){
            return false;
        }
        else{
            if(add){
                dispatch(addTag({name:tag.name,id:tag.id}));

                // update lead in all queues
                // tag value example: `${tag.id}*${tag.name}`
                const tagStr = `${tag.id}*${tag.name}`

                if(existingTags === null){
                    dispatch(updateSpecificLead({id: leadId, field: 'tag_ids', value: `${tagStr}`}))
                } else if(!existingTags.includes(tagStr)){
                    dispatch(updateSpecificLead({id: leadId, field: 'tag_ids', value: `${existingTags},${tagStr}`}))
                }
            }
            return jsonData.tagId;
        }
    }
    catch (error) {
        console.error("Error fetching data:", error);
    }
};
const addTagToLead = async (setSelectedTags, selectedTags, tag) => {
    setSelectedTags([...selectedTags, tag]);
};

export {removeTagFromLead, saveTag, addTagToLead};
