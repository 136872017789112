import React, {useState} from 'react';
import '../../css/conversation.css';
import { formatPhoneNumber, formatTimestamp } from '../../utilities/dashFunction';

function ConvoBlock({convo,handleExpanded}) {
    let title=convo?.borrower_first+' '+convo?.borrower_last;
    if(!convo?.borrower_first && !convo?.borrower_last){
        title=convo?.message_to;
    }

    return (
        <div className={`convoPreviewWrap ${convo?.message_read==='no' ? 'unreadMessage':''}`} onClick={()=>handleExpanded(convo?.convo_id)} style={{width: 'calc(100% - 10px)',padding: '5px',minHeight: '90px'}}>
            <div className="quilityDataLine" style={{display: 'flex', borderBottom: 'none'}}>
                {/* <div className={`quilityDataLineTitle textOverflowControl`} style={{flex: '2 1 0%'}}>{convo?.borrower_first ? convo?.borrower_first+' '+convo?.borrower_last : (convo?.convo_id.includes(":") && formatPhoneNumber(atob(convo?.convo_id)?.split(':')[1]) ) }</div> */}
                <div className={`quilityDataLineTitle textOverflowControl`} style={{flex: '2 1 0%'}}>{convo?.borrower_first && convo?.borrower_first+' '+convo?.borrower_last }</div>

                <div className="quilityDataLineValue" style={{flex: '1 1 0%', cursor: 'pointer'}}></div>
               {convo?.time && <div className="quilityDataLineValue" style={{cursor: 'pointer'}}>{formatTimestamp(convo?.time)}</div> }
            </div>
            <div className="frontMessagePreview">{convo?.message}</div>
        </div>
    );
}

export default ConvoBlock;

/*<div className="conversationPreviewWrapper" onClick={()=>handleExpanded(convo.convo_id)}>
            <div className="convoPreviewHeader">

                <div className={`convoPreviewName ${convo.message_read==='no' ? 'unreadTitle':''}`}>{title} <span className="convoPreviewTime">{formatTimestamp(convo.time)}</span></div>
            </div>
            <div className={`convoPreviewContent ${convo.message_read==='no' ? 'unreadContent':''}`}>
                <div className="convoPreview">{convo.message}</div>

            </div>
        </div>*/
