import React, {useState, useRef, useEffect} from 'react';
import '../../css/migration.css';
import image from '../../assets/icons/QSF.svg';
import loader from '../../assets/icons/loading-loader.gif';
import zap from '../../assets/icons/zap-fast.svg';
import switchCross from '../../assets/icons/switch-horizontal.svg';
import mail from '../../assets/icons/mail.svg';
import landing from '../../assets/icons/landingImage.png';
import MigrationIneligible from './ineligible';
import MigrationNeedsRegistration from './needsRegistration';
import ActiveBottom from './activeBottom';
import MigrationComplete from './complete';
import { checkEligibility, migrate } from '../../utilities/migrationFunctions';


function MigrationLandingPage({agentCode}) { 
    const [eligibility, setEligibility] = useState(false);
    const [agentId, setAgentId] = useState(false);
    const [loading, setLoading] = useState(false);
    const [eligibilityChecked, setEligibilityChecked] = useState(false);
    const [closeModal, setCloseModal] = useState(false);
    const [migrationInitiated, setMigrationInitiated] = useState(false);
    const targetRef = useRef(null);

    const handleSetMigration= async ()=>{
        // just need to create the agent, and sync then call the endpoint
        await migrate(agentId,eligibility,setLoading);
        setMigrationInitiated(true);
    }
    const handleEligibility=()=>{
        checkEligibility(agentCode, setEligibilityChecked, setEligibility, setAgentId);
    }
    // Step 2: Function to handle the scroll action
    const scrollToDiv = () => {
        targetRef.current.scrollIntoView({
            behavior: 'smooth', // Optional: smooth scroll effect
            block: 'start',     // Scroll to the start of the div
        });
    };
    useEffect( () => {
        if(agentCode){
            handleEligibility()
        }
	},[])
    return (
        eligibilityChecked ? (
            <div className="landingWrapper">
                {migrationInitiated ? (
                    <MigrationComplete />
                ) : (
                    <>
                        {!eligibility ? (
                            closeModal ? (
                                null
                            ) : (
                                <MigrationIneligible setCloseModal={setCloseModal}/>
                            )
                        ) : eligibility==='signup' ? (
                            closeModal ? (
                                null
                            ) : (
                                <MigrationNeedsRegistration setCloseModal={setCloseModal} link={[`${process.env.REACT_APP_FUNNEL_HQ_QUILITY}form/funnel-waitlist/`]} mainText="Please join the Funnel waiting list." smallText="You'll receive an email when it's your turn to on-board." buttonText="Join Now"/>
                            )
                        ) : eligibility==='course' ? (
                            closeModal ? (
                                null
                            ) : (
                                <MigrationNeedsRegistration setCloseModal={setCloseModal} link={[``]} mainText="Please Take The Certification Course" smallText="You have not taken the certification course yet. In order to be eligible to sign up, you need to take the certification course first." buttonText="View Course"/>
                            )
                        ) : eligibility==='both' && (
                            closeModal ? (
                                null
                            ) : (
                                <MigrationNeedsRegistration setCloseModal={setCloseModal} link={[`${process.env.REACT_APP_FUNNEL_HQ_QUILITY}form/funnel-waitlist/`,'a']} mainText="Please Join The Wait List and Take The Certification Course" smallText="You have not taken the certification course or joined the waitlist. In order to be eligible to sign up, you need to join the waitlist and take the certification course first." buttonText={["Join Waitlist","View Course"]}/>
                            )
                        )}
                        <div className="logoSpacer"><img src={image} className="migrationLogo" alt="Logo" /></div>
                        <div className="migrationTitle">Ready to Make the Switch?</div>
                        {eligibility===true && (
                            <div className="landingButton" onClick={scrollToDiv}>Let's Go</div>
                        )}
                        
                        <div className="logoSpacer" style={{marginTop:'10px'}}><img src={landing} className="mainImage" alt="image of queue" /></div>
                        <div className="bottomWrapper">
                            <div className="spiel">Switching from Switchboard<span className="trade">®</span> Activate to Funnel is simple and hassle-free.</div>
                            <div className="subSpiel">Migrate to Switchboard<span className="subTrade">®</span> Funnel Today!</div>
                            <div className="descriptionBoxWrapper">
                                <div className="descriptionBlock"> 
                                    <div className="innerDescription">
                                        <div className="descriptionIconWrapper"><img src={zap} className="descriptionIcon" alt="mail logo" /></div>
                                        <div className="descriptionBlockText">The quick and easy migration process takes approximately 30 minutes.</div>
                                    </div>
                                </div>
                                <div className="descriptionBlock"> 
                                    <div className="innerDescription">
                                        <div className="descriptionIconWrapper"><img src={switchCross} className="descriptionIcon" alt="mail logo" /></div>
                                        <div className="descriptionBlockText">All existing data in your Activate account will be transferred over to Funnel.</div>
                                    </div>
                                </div>
                                <div className="descriptionBlock"> 
                                    <div className="innerDescription">
                                        <div className="descriptionIconWrapper"><img src={mail} className="descriptionIcon" alt="mail logo" /></div>
                                        <div className="descriptionBlockText">When your account is ready, you'll receive an email with access instructions.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {eligibility===true || eligibility==='account' && (
                            <>
                                <ActiveBottom handleSetMigration={handleSetMigration} />
                                <div className="veryBottomTextWrapper" ref={targetRef} >
                                    <div className="veryBottomText">
                                    You can close this window at any time and revisit the process later.
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}
                {loading && (
                    <div className="loadingScreen">
                        <div className="loaderWrapper">
                            <img src={loader} alt="Loading Gif" style={{width: '100%'}} />
                        </div>
                    </div>
                )}
            </div>
        ) : (
            <>
                <div className="logoSpacer"><img src={image} className="migrationLogo" alt="Logo" /></div>
                <div className="migrationTitle">Ready to Make the Switch?</div>
                <div className="logoSpacer" style={{marginTop:'10px'}}><img src={landing} className="mainImage" alt="image of queue" /></div>
                <div className="loadingScreen">
                    <div className="loaderWrapper">
                        <img src={loader} alt="Loading Gif" style={{width: '100%'}} />
                    </div>
                </div>
            </>
        )
    );
}

export default MigrationLandingPage;
