import React, {useState} from "react";
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { useSelector } from 'react-redux';
import { fetchWithDefaults } from '../../config/fetchDefault';

const AddAudioElement = ({setVoicemails, voicemails, setAddRecording, type}) => {
    const agentId=useSelector((state) => state.agent.userData).agentId;
    const [name, setName] = useState('');
    const [blob,setBlob] = useState();
    const [error, setError] = useState(false);
    const handleInputChange = (event) => {
      setName(event.target.value);
    };
    const recorderControls = useAudioRecorder(
        {
        noiseSuppression: true,
        echoCancellation: true,
        },
        (err) => console.table(err) // onNotAllowedOrFound
    );
    const addAudioElement = (blob) => {
        const url = URL.createObjectURL(blob);
        const audio = document.createElement('audio');
        audio.src = url;
        audio.controls = true;
        setBlob(blob);


        const audioWrapper = document.querySelector('.recordedWrapper');
        if (audioWrapper) {
            audioWrapper.innerHTML = ''; // Clear any existing content
            //audioWrapper.innerHTML = button.outerHTML;
            audioWrapper.appendChild(audio);
        }
    };

    const saveRecording = async (blob,name) => {
        let path=window.location.origin;
        if(path.includes('localhost')){
            path='https://localhost';
        }
        if(name !== ''){
            const formData = new FormData();
            formData.append('audio', blob, 'recording');
            formData.append('name', name);
            formData.append('agent', agentId);
            formData.append('type', type);

            try {
                const backend_port = process.env.REACT_APP_BACKEND_PORT ? process.env.REACT_APP_BACKEND_PORT : 3333
                const response = await fetchWithDefaults(`${path}:${backend_port}/voicemail/${type}`, {
                    method: 'POST',
                    body: formData,
                  });
            
                if (response.ok) {
                    const jsonData = await response.json();
                    if(jsonData.status){
                        const newVm={
                            agent_id: agentId,
                            created: jsonData.date,
                            ext: "mp3",
                            id: jsonData.file,
                            name: name
                        }
                        if(voicemails.length > 0){
                            setVoicemails([newVm, ...voicemails]); 
                        }
                        else{
                            setVoicemails([newVm]); 
                        }
                        setAddRecording(false);
                    }   
                    else{

                    }
                } else {
                    console.error('Error uploading file');
                    // Handle error, if needed
                }
            } catch (error) {
                console.error('Error:', error.message);
                // Handle error, if needed
            }
        }
        else{
            setError("You need to name this recording")
        }
    };
    const clearError=()=>{
        if(error){
            setError(false);
        }
    }
    return (
        <div className="audioWrapper">
            <div className="qualifierFieldWrapper" style={{width:'100%'}}>
                {error && (
                    <div className='errorMessage'>{error}</div>
                )}
                <div className="qualifierFieldTitle" style={{width: 'unset',display: 'block',marginBottom: '5px'}}>
                    Name This Recording
                </div>
                <input 
                    type="text" 
                    className="qualifierFieldInput" 
                    placeholder="Name"
                    value={name}
                    onChange={handleInputChange}
                    onFocus={clearError}
                />
            </div>
            <AudioRecorder
                onRecordingComplete={(blob) => addAudioElement(blob)}
                recorderControls={recorderControls}
                showVisualizer={true}
                style={{margin: '10px auto'}}
            />
            <div className="recordedWrapper"></div>
            {blob && <div className="accentButton solidQuilityActiveButton adjust" onClick={()=>saveRecording(blob,name)}>Save Recording</div>}
        </div>
    );
}
export default AddAudioElement;

