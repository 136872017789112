const backend_port = process.env.REACT_APP_BACKEND_PORT ? process.env.REACT_APP_BACKEND_PORT : 3333
const apiUrl = window.location.hostname === 'localhost' ? `https://localhost:${backend_port}` : `https://${window.location.hostname}:${backend_port}`;
//const csrfToken = localStorage.getItem('keepMeAlive');



export const fetchWithDefaults = async (url, options = {}) => {
  const csrfToken = await localStorage.getItem('keepMeAlive');
  let defaultHeaders = {
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken, 
  };
  const headers = { ...defaultHeaders, ...options.headers };
  const mergedOptions = { ...options, headers };

  // Call the function to continuously update X-CSRF-Token header
  updateCSRFToken(defaultHeaders, csrfToken);

  const response =await fetch(`${apiUrl}${url}`, mergedOptions);
  const responseHeaders = response.headers;
  const csrf = responseHeaders.get('X-CSRF-Token');
  if(csrf){
    localStorage.setItem('KeepMeAlive',csrf)
  }
  return response;
};



function updateCSRFToken(headers, csrfToken) {
  headers['X-CSRF-Token'] = csrfToken;

  if (headers['X-CSRF-Token'] !== null) {

  } 
  else {
      setTimeout(() => updateCSRFToken(headers), 500);
  }
}
