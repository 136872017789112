import moment from 'moment-timezone';

function UnixTimestampToDate(unixTimestamp) {
    // Create a new Date object from the Unix timestamp (in milliseconds)
    const date = new Date(unixTimestamp * 1000);

    // Get the month and day from the date object
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');

    // Format the date as "MM/DD"
    const formattedDate = `${month}/${day}`;

    return formattedDate;
}
const isWithin24Hours = (timestamp) => {
    if (!timestamp) return false; // Check if the timestamp is null or undefined

    const givenTime = new Date(timestamp); // Convert the timestamp to a Date object
    const currentTime = new Date(); // Get the current time

    // Calculate the difference in time (milliseconds)
    const timeDifference = currentTime - givenTime;

    // Convert the time difference to hours and check if it's within 24 hours
    const hoursDifference = timeDifference / (1000 * 60 * 60);

    return hoursDifference <= 24;
};
const getTimestampForTimezone = (timezone) => {
    // Get the current time in the specified timezone using moment-timezone
    const now = moment.tz(timezone);

    // Format the timestamp as 'YYYY-MM-DD HH:mm:ss'
    return now.format('YYYY-MM-DD HH:mm:ss');
};

const getNearestAge = (birthday) => {
    const today = new Date();
    const birthDate = new Date(birthday);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    // Adjust age if the birthday hasn't occurred yet this year
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    // Adjust age if the birthday was more than 6 months ago
    if (Math.abs(today.getMonth() - birthDate.getMonth()) >= 6) {
        age++;
    }

    return age;
}
const validDOB = (dateInput) => {
    const isFeature660Enabled = process.env.REACT_APP_FUNNEL_FEATURE_660 === 'true'

    if (!dateInput) {
        // Valid case: empty string
        return { date: '', isValid: true, error: '' };
    }

    // Regular expression to match MM/DD/YYYY format
    const datePattern = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/;

    if (datePattern.test(dateInput)) {
        // Parse the date to check if it's valid
        const [month, day, year] = dateInput.split('/').map(Number);
        const date = new Date(year, month - 1, day);

        // Validate that the parsed date matches the input date
        if (isFeature660Enabled === true) {
            if (
                date.getFullYear() === year &&
                date.getMonth() === month - 1 &&
                date.getDate() === day &&
                Date.now() >= date.getTime()
            ) {
                return { date: dateInput, isValid: true, error: '' };
            }
        } else if (
            date.getFullYear() === year &&
            date.getMonth() === month - 1 &&
            date.getDate() === day
        ) {
            return { date: dateInput, isValid: true, error: '' };
        }
    }

    // Invalid case
    return { date: '', isValid: false, error: 'Invalid DOB' };
};
const calculateAge = (dateString) => {
    const [month, day, year] = dateString.split('/').map(Number);
    const birthDate = new Date(year, month - 1, day); // month is zero-indexed
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();

    // Adjust age if the birth date hasn't occurred yet this year
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        age--;
    }

    return age;
};
const dobFormat = (dateInput) => {
    const dateOptions = { timeZone: 'UTC', month: '2-digit', day: '2-digit', year: 'numeric' }
    const dateFormatter = new Intl.DateTimeFormat('en-US', dateOptions)

    // using Intl formatter with 'UTC' timezone option
    // to deal with issues that might arise due to the timezone offset
    // that might be included in the dateInput string
    if (typeof dateInput === 'string' && (dateInput.includes('T') || dateInput.includes('/'))) {
        // cannot return formattedDateStr.
        // there might be browsers that are configured
        // in such a way that will cause dateFormatter.format(dateInput)
        // to return the date in a different format.
        const formattedDateStr = dateFormatter.format(new Date(dateInput))
        const formattedDate = new Date(formattedDateStr)

        return [
            String(formattedDate.getMonth() + 1).padStart(2, '0'),
            String(formattedDate.getDate()).padStart(2, '0'),
            formattedDate.getFullYear()
        ].join('/');
    }

    return ''
}

// Calculate age based on 'Current Age' Logic found here: https://quility.atlassian.net/wiki/spaces/NI/pages/1118011434/Product+Recommendation+Story+3+Age+Logic+by+Product#S3-2-%7C-Develop-Age-Logic
const getCurrentAge = (birthDate) => {
    const now = moment();
    const m_birthDate = moment(birthDate)

    const ageDiff = now.diff(m_birthDate, 'days')

    return Math.trunc(ageDiff / 365)
}

const timeStampToMonthDate = (timeStamp) => {
    const date = new Date(timeStamp)
    const month = (date.getMonth() + 1).toString().padStart(2, '0') // Months are zero-based
    const day = date.getDate().toString().padStart(2, '0')

    return `${month}/${day}`
}

const getFormattedDate =()=> {
    const date = new Date(); // Get the current date
    const year = date.getFullYear(); // Get the year
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get the month (0-indexed) and pad with 0
    const day = String(date.getDate()).padStart(2, '0'); // Get the day and pad with 0
    
    return `${year}-${month}-${day}`;
  }

export { UnixTimestampToDate, calculateAge, isWithin24Hours, getTimestampForTimezone, getNearestAge, dobFormat, validDOB, getCurrentAge, timeStampToMonthDate, getFormattedDate };