import React, { useState, useEffect } from 'react';
import '../../css/leadCard.css';
import { fetchWithDefaults } from '../../config/fetchDefault';
import LeadData from '../conversations/leadData';
import QueueScripts from '../callQueue/elements/scripts';
import QueueConvo from '../callQueue/elements/conversation';
import QueueNotes from '../callQueue/elements/notes';
import Recommendation from '../callQueue/elements/sections/recommendation';
import RightQueueHeader from '../callQueue/elements/rightQueueHeader';
import Email from '../email/email';
import { getTimestampForTimezone, UnixTimestampToDate } from '../../utilities/timeFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { setCall } from '../../features/callSlice';
import LeadCallRecordings from './leadRecording';
import Scripting from '../scripting/scripting';
import Qualifier from '../qualifier/qualifier';
import { updateSpecificLead } from '../../features/queueSlice';
import { runVM } from '../../utilities/virtualMentor';
import { addTag, handleUpdateQueueLead, removeTag, updateStatusName } from '../../utilities/leadFunctions';
import DispositionBlock from '../callQueue/elements/sections/dispositions';
import Dial from '../callQueue/elements/handleDial';
import { SuccessAlert } from '../successAlert';
import image from '../../assets/icons/loading-loader.gif';
import { determineHealthRating } from '../../utilities/virtualMentor';
import { setLoadingGif } from '../../features/loadingSlice';
import Script from '../scripting/script';
import { canMessageCurrentLeadTCPA } from '../../utilities/tcpafunctions';
import AddEvent from '../callQueue/elements/sections/addEvent';

function LeadCard({ theCall, jumpIntoQueue, dialPackage, setLoading, data, textingNumbers, handleSoftPhone, device, setCallerId, from, newAppt, setNewAppt, permission }) {
    const isFeature970Enabled = process.env.REACT_APP_FUNNEL_FEATURE_970 === 'true'
    const isFeature875Enabled = process.env.REACT_APP_FUNNEL_FEATURE_875 === 'true'

    const dispatch = useDispatch();

    const [apptSet, setApptSet] = useState(false);
    const [selectedTab, setSelectedTab] = useState('term');
    const queue = useSelector((state) => state.queue.queue);
    const loadingGif = useSelector((state) => state.loading.loading);
    const [successTrigger, setSuccessTrigger] = useState(false);
    const [flowData, setFlowData] = useState(false);
    const [isEmailOpen, setIsEmailOpen] = useState(false);
    const [scriptType, setScriptType] = useState('dynamic');
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [term, setTerm] = useState('15');
    const [faceAmount, setFaceAmount] = useState('100000');
    const [possibleConditions, setPossibleConditions] = useState([]);
    const agentInfo = useSelector((state) => state.agent.userData);
    const [qualifier, setQualifier] = useState(false);
    const [currentScript, setCurrentScript] = useState(null);
    const [results, setResults] = useState(false);
    const [alreadyTagged, setAlreadyTagged] = useState(false);
    const [lead, setLead] = useState(false);
    const [profile, setProfile] = useState([]);
    const [scripting, setScripting] = useState(false);
    const [selected, setSelected] = useState(1);
    const [conversations, setConversations] = useState([]);
    const [healthRating, setHealthRating] = useState('SNT')

    const handleSelectedTab = (type) => {
        let typeId;
        if (type === 'term') {
            typeId = '7';
        }
        else {
            typeId = '6'
        }
        handleProfileUpdate('productOption', typeId)
        setSelectedTab(type)
    }
    const selectTab = (index) => {
        if (index === 5) {
            setScripting(true)
        }
        else {
            //setScripting(false);
        }
        setSelected(index)
    }
    const [newObjection, setNewObjection] = useState(false);
    const handleNewObjection = (objection) => {
        setNewObjection(objection);
    }
    const agentId = agentInfo.agentId;
    const [newEvent, setNewEvent] = useState(false);
    const [date, setDate] = useState(false);
    const [time, setTime] = useState(false);
    const [event, setEvent] = useState(false);

    const stopFlow = async (id) => {
        try {
            const response = await fetchWithDefaults(`/stopFlow?flow=${id}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setFlowData(false);
            let index = 0;
            setLead(prevData => {
                const newData = [...prevData];
                // Check if index is valid
                if (index >= 0 && index < newData.length) {
                    // Update the field value for the object at the specified index
                    newData[index] = {
                        ...newData[index],
                        ['wf_name']: null,
                        ['wf_id']: null
                    };
                }
                return newData;
            });

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handleQualifier = () => {
        setQualifier(!qualifier);
    }


    function transformTags(input) {
        // Split the input string by comma to get individual pairs
        const pairs = input.split(',');

        // Transform each pair into the desired format
        const transformedArray = pairs.map(pair => {
            // Split each pair by '*' to get id and name
            const [id, name] = pair.split('*');

            return { name, id };
        });

        return transformedArray;
    }
    const parseWeight = (input) => {
        // Use a regular expression to extract the numeric part
        const parsedWeight = parseInt(input.match(/\d+/), 10);
        return parsedWeight;
    };

    useEffect(() => {
        //categorizeRows();

        const tobaccoUse = (data && data[0].tobacco !== undefined && data[0].tobacco !== null) ? data[0].tobacco : 'no'

        setLead(data)
        setProfile({
            borrower_sex: data ? data[0].borrower_sex : 'male',
            borrower_age: data ? data[0].borrower_age : null,
            state: data ? data[0].state : null,
            feet: data ? data[0].feet : null,
            inches: data ? data[0].inches : null,
            weight: data && data[0].weight ? parseWeight(data[0].weight) : null,
            faceAmount: faceAmount,
            tobacco: tobaccoUse,
            term: term,
            productOption: 7,
            qualifiers: [],
            healthRating: determineHealthRating(tobaccoUse, 'S'),
            borrower_sex: data ? data[0].borrower_sex : null,
            borrower_dob: data ? data[0].borrower_dob : null,
        })
    }, [data[0].lead_id]);
    useEffect(() => {
        if (data[0].tag_ids) {
            const tag = transformTags(data[0].tag_ids);
            setAlreadyTagged(tag);
        }

    }, [data[0].tag_ids]);

    const closeData = () => {
        setFlowData(false);
    }
    const makeDial = async (toNumber, callee, fromNum) => {
        try {

            // Use the provided `fromNum` or default to `data[0].call_from`
            if (!fromNum) {
                fromNum = data[0].call_from;
            }
            // Attempt to connect the call
            const call = await device.connect({
                params: {
                    To: `+1${toNumber}`,
                    callerId: fromNum,
                    user: agentId,
                    tone: "default",
                    leadId: data[0].lead_id
                }
            });
            // Handle the call if the connection is successful
            const callTime = getTimestampForTimezone(agentInfo.timezone);
            dispatch(updateSpecificLead({ id: data[0].lead_id, field: 'last_contacted', value: callTime }))
            dispatch(setCall(call));
            handleSoftPhone(call.status());
            setCallerId({ name: callee, lead_id: data[0].lead_id, outbound_number: fromNum, last_contact: data[0].last_contacted });
        } catch (error) {
            // Log the error for debugging purposes
            console.error("Error making the call:", error);

            // Handle different types of errors accordingly
            if (error.message.includes('Twilio')) {
                console.error("Twilio-related error:", error.message);
                // Handle Twilio-specific errors
                alert("Failed to connect the call. Please check the phone number and try again.");
            } else if (error.message.includes('Network')) {
                console.error("Network-related error:", error.message);
                // Handle network-related errors
                alert("Network issue. Please check your internet connection and try again.");
            } else {
                console.error("Unexpected error:", error.message);
                // Handle other unexpected errors
                alert("An unexpected error occurred. Please try again later.");
            }

            // Optionally, you could retry the call or take other actions depending on the error
        }
    }
    const newEventPackage = [{ setNewEvent: setNewEvent, setTime: setTime, setDate: setDate }]
    const [timestamp, setTimestamp] = useState(null);
    const handleLeadData = (field, value) => {
        console.log(field)
        console.log(value)
        let index = 0;
        setLead(prevData => {
            const newData = [...prevData];
            // Check if index is valid
            if (index >= 0 && index < newData.length) {
                // Update the field value for the object at the specified index
                newData[index] = {
                    ...newData[index],
                    [field]: value
                };
            }
            return newData;
        });
        handleUpdateQueueLead(field, value, lead[0].lead_id, queue, dispatch)
    }
    const handleScriptType = (type) => {
        setScriptType(type)
    }
    const handleProfileUpdate = (key, value) => {
        setProfile(prevState => ({
            ...prevState,
            [key]: value
        }));
    }
    const handleSelectTab = (tab) => {
        if (tab === 4) {
            setScripting(true);
            selectTab(4)
            handleScriptType('qual')

        }
        else if (tab === 0) {
            selectTab(4)
            setScripting(true);
            handleScriptType('script')
        }
        else {
            selectTab(tab)
        }
    }
    const returnToLead = () => {
        setScripting(false);
        setQualifier(false);
        selectTab(1)
    }
    const handleSetQualifier = () => {
        setQualifier(false)
    }
    const isStateEmpty = (state) => {
        return !Object.values(state).some(value => value !== null);
    };
    useEffect(() => {
        const tobaccoRatings = ['ST', 'PT']
        const nonTobaccoRatings = ['PPNT', 'PNT', 'SPNT', 'SNT']
        if (lead && !isStateEmpty(profile) && ((profile.tobacco === 'yes' && tobaccoRatings.includes(profile.healthRating)) || (profile.tobacco === 'no' && nonTobaccoRatings.includes(profile.healthRating)))) {
            if (isFeature970Enabled === true) {
                dispatch(setLoadingGif(true));
                runVM(profile, setResults, lead[0].lead_id, dispatch);
            } else {
                runVM(profile, setResults, lead[0].lead_id, dispatch);
                dispatch(setLoadingGif(true));
            }

        } else if (lead && profile.productOption !== '7') {
            dispatch(setLoadingGif(true));
            runVM(profile, setResults, lead[0].lead_id, dispatch);
        }
    }, [profile]);
    useEffect(() => {
        setApptSet(false);
    }, [lead && lead[0].lead_id]);
    const handleSetScripting = () => {
        setScripting(true);
        selectTab(4)
        handleScriptType('qual')
    }
    const expiration = (parseInt(data[0].exclusivity) - parseInt(timestamp));

    const [canMessageTCPA, setCanMessageTCPA] = useState(false)

    useEffect(() => {
        if (isFeature875Enabled) {
            setCanMessageTCPA(canMessageCurrentLeadTCPA(lead, conversations))
        }
    }, [conversations])

    const queueConvoProps = {
        handleLeadData,
        expiration,
        setLoading,
        textingNumbers,
        setConversations,
        conversations,
        agentId,
        canMessageTCPA,
        setCanMessageTCPA,
        teamId: agentInfo.teamId,
        currentLeadId: lead[0]?.lead_id ?? null,
        currentLead: lead[0],
        lead: lead[0],
    }

    const dispositionBlockProps = {
        handleLeadData,
        removeTag,
        addTag,
        from: "dash",
        updateStatusName,
        canMessageTCPA,
        leadId: lead[0]?.lead_id,
        tags: lead[0]?.tag_ids,
        type: lead[0]?.parent_status,
    }

    const dynamicScriptProps = {
        handleSelectedTab,
        quoteTab: selectedTab,
        handleProfileUpdate,
        setFaceAmount,
        setLead,
        possibleConditions,
        setPossibleConditions,
        selectedProducts,
        setSelectedProducts,
        setResults,
        profile,
        setProfile,
        lead,
        handleLeadData,
    }

    const scriptingProps = {
        lead,
        handleLeadData,
        setProfile,
        possibleConditions,
        setPossibleConditions,
        setLead,
        profile,
        setApptSet,
        newEventPackage
    }

    const qualifierProps = {
        from: 'script',
        possibleConditions,
        setPossibleConditions,
        setProfile,
        profile,
        setResults,
        handleLeadData,
        handleSetQualifier,
        lead,
        agentId,
    }

    const leadDataNonConditionalProps = {
        from,
        setApptSet,
        handleSetScripting,
        handleScriptType,
        setScripting,
        permission,
        selectTab,
        setQualifier,
        selectedProducts,
        setSelectedProducts,
        possibleConditions,
        setPossibleConditions,
        profile,
        setProfile,
        setResults,
        setFlowData,
        handleLeadData,
        newEventPackage,
        setEvent,
        setNewAppt,
        newAppt,
        expiration,
        alreadyTagged,
        qualifier,
        lead,
        handleQualifier,
    }

    const dialProps = {
        apptSet,
        theCall,
        jumpIntoQueue,
        handleLeadData,
        from: "session",
        makeDial: dialPackage !== undefined ? dialPackage[0].makeDial : null,
        setDialGroupDone: dialPackage !== undefined ? dialPackage[0].setDialGroupDone : null,
        incrementQueue: dialPackage !== undefined ? dialPackage[0].incrementQueue : null,
        currentLead: dialPackage !== undefined ? dialPackage[0].currentLead : null,
        dialAmount: dialPackage !== undefined ? dialPackage[0].dialAmount : null,
        device: dialPackage !== undefined ? dialPackage[0].device : null,
    }

    const reccomendationProps = {
        handleSelectedTab,
        selectedTab,
        selectedProducts,
        setSelectedProducts,
        handleProfileUpdate,
        setFaceAmount,
        faceAmount,
        term,
        setTerm,
        possibleConditions,
        results,
        lead,
        profile,
        healthRating,
        setHealthRating,
    }

    const rightQueueProps = {
        recording: lead ? lead[0].recording_count : null,
        selected,
        handleSelectTab,
        setQualifier,
    }

    // =========== apointemnt popup api =========
    const [calendar, setCalendar] = useState(false);

    const setAppointment = async (event, video) => {
        try {
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({
                    agent: agentId,
                    team: agentInfo.teamId,
                    leadId: lead[0].lead_id,
                    event: event,
                    video: video
                }),
            };
            const response = await fetchWithDefaults('/google/create/event', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setNewAppt(jsonData.event)
            setNewEvent(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }



    return (
        <>
            {/*<LeadCardTopBar expiration={expiration} setLoading={setLoading} textingNumbers={textingNumbers} qualifier={qualifier} handleQualifier={handleQualifier} calendarChange={calendarChange} setCalendarUsing={setCalendarUsing} setEvent={setEvent} newEventPackage={newEventPackage} newAppt={newAppt} setNewAppt={setNewAppt} setNewEvent={setNewEvent} data={data}/>*/}
            {lead && (
                <>
                    <SuccessAlert message={'Status Changed'} setSuccessTrigger={setSuccessTrigger} successTrigger={successTrigger} />
                    {data[0].parent_status === 'client' ? (
                        <div className="leadCardDataWrapper">
                            <div className="centerQueue">
                                {scripting && (
                                    <>
                                        <div className="recLink solidQuilityActiveButton" style={{ position: 'absolute', top: '10px' }} onClick={() => returnToLead()}>Return</div>
                                        <div className="productTabs" style={{ marginTop: '45px' }}>
                                            <div className={`productTab ${scriptType === 'script' && 'selectedTab'}`} onClick={() => handleScriptType('script')}>Scripts</div>
                                            <div className={`productTab ${scriptType === 'dynamic' && 'selectedTab'}`} onClick={() => handleScriptType('dynamic')}>Dynamic Script <span style={{ fontSize: '12px', position: 'absolute' }}>  Beta</span></div>
                                            <div className={`productTab ${scriptType === 'qual' && 'selectedTab'}`} onClick={() => handleScriptType('qual')}>Qualifier Q's</div>
                                        </div>
                                    </>
                                )}
                                {scripting && !qualifier ? (
                                    scriptType === 'dynamic' ? (
                                        <Scripting {...dynamicScriptProps} />
                                    ) : scriptType === 'script' ? (
                                        <Script {...scriptingProps} />
                                    ) : (
                                        <span className="qualifierAdjustment">
                                            <Qualifier {...qualifierProps} />
                                        </span>
                                    )
                                ) : (
                                    <LeadData {...leadDataNonConditionalProps} makeDial={from === 'session' ? dialPackage[0].makeDial : makeDial} />
                                )}
                                <div className="dispositionWrapper">
                                    {from === 'session' ? (
                                        <Dial {...dialProps} />
                                    ) : (
                                        <DispositionBlock {...dispositionBlockProps} />
                                    )}
                                </div>
                            </div>
                            <div className="rightQueue">
                                <RightQueueHeader recording={lead[0].recording_count} selected={selected} handleSelectTab={handleSelectTab} setQualifier={setQualifier} />
                                {selected == 0 ? (
                                    <QueueScripts handleNewObjection={handleNewObjection} currentLead={lead[0]} scriptId={currentScript} />
                                ) : selected == 1 ? (
                                    //ActualConversation({setLoading,lead,conversation,travelPathway,handleSendText,convoId,handleInputChange,inputValue})
                                    <QueueConvo {...queueConvoProps} />
                                ) : selected == 2 ? (
                                    expiration > 0 || isNaN(expiration) ? (
                                        <Email email={lead[0].email} setIsEmailOpen={setIsEmailOpen} />
                                    ) : (
                                        <div className="noResults">You can no longer Email this lead</div>
                                    )
                                ) : selected == 3 ? (
                                    <QueueNotes handleLeadData={handleLeadData} notes={lead[0].notes} leadId={lead[0].lead_id} agentId={agentId} plan={lead[0].policy_plan} />
                                ) : selected == 6 ? (
                                    <LeadCallRecordings lead={lead[0].lead_id} />
                                ) : (
                                    <Recommendation {...reccomendationProps} />
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="leadCardDataWrapper">
                            <div className="centerQueue">
                                {scripting && (
                                    <>
                                        <div className="recLink solidQuilityActiveButton" style={{ position: 'absolute', top: '10px' }} onClick={() => returnToLead()}>Return</div>
                                        <div className="productTabs" style={{ marginTop: '45px' }}>
                                            <div className={`productTab ${scriptType === 'script' && 'selectedTab'}`} onClick={() => handleScriptType('script')}>Scripts</div>
                                            <div className={`productTab ${scriptType === 'dynamic' && 'selectedTab'}`} onClick={() => handleScriptType('dynamic')}>Dynamic Script</div>
                                            <div className={`productTab ${scriptType === 'qual' && 'selectedTab'}`} onClick={() => handleScriptType('qual')}>Qualifier Q's</div>
                                        </div>
                                    </>
                                )}
                                {scripting && !qualifier ? (
                                    scriptType === 'dynamic' ? (
                                        <Scripting {...dynamicScriptProps} />
                                    ) : scriptType === 'script' ? (
                                        <Script {...scriptingProps} />
                                    ) : (
                                        <span className="qualifierAdjustment">
                                            <Qualifier {...qualifierProps} />
                                        </span>
                                    )
                                ) : (
                                    <LeadData {...leadDataNonConditionalProps} makeDial={from === 'session' ? dialPackage[0].makeDial : makeDial} />
                                )}
                                <div className="dispositionWrapper">
                                    {from === 'session' ? (
                                        <Dial {...dialProps} />
                                    ) : (
                                        <div className="dispositionHolder">
                                            <DispositionBlock {...dispositionBlockProps} />
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="rightQueue">
                                <RightQueueHeader recording={lead[0].recording_count} selected={selected} handleSelectTab={handleSelectTab} setQualifier={setQualifier} />
                                {selected == 0 ? (
                                    <QueueScripts handleNewObjection={handleNewObjection} currentLead={lead[0]} scriptId={currentScript} />
                                ) : selected == 1 ? (
                                    //ActualConversation({setLoading,lead,conversation,travelPathway,handleSendText,convoId,handleInputChange,inputValue})
                                    <QueueConvo {...queueConvoProps} />
                                ) : selected == 2 ? (
                                    expiration > 0 || isNaN(expiration) ? (
                                        <Email email={lead[0].email} setIsEmailOpen={setIsEmailOpen} />
                                    ) : (
                                        <div className="noResults">You can no longer Email this lead</div>
                                    )
                                ) : selected == 3 ? (
                                    <QueueNotes handleLeadData={handleLeadData} notes={lead[0].notes} leadId={lead[0].lead_id} agentId={agentId} plan={lead[0].policy_plan} />
                                ) : selected == 6 ? (
                                    <LeadCallRecordings lead={lead[0].lead_id} />
                                ) : (
                                    <Recommendation {...reccomendationProps} />
                                )}
                            </div>
                        </div>
                    )}

                    {newEvent && (
                        null
                    )}
                    {loadingGif && (
                        <div className="loadingScreen">
                            <div className="loaderWrapper">
                                <img src={image} alt="Loading Gif" style={{ width: '100%' }} />
                            </div>
                        </div>
                    )}
                </>
            )}
            {flowData && (
                <div className="flowDataWrapper">
                    <div className="flowDataContent">
                        <div className="closeWindow" onClick={() => closeData()}>X</div>
                        {flowData.map((item, index) => (
                            <>
                                <div className="title">Workflow Info</div>
                                <div className="flowDataInfoWrapper">
                                    <div className="flowDataInfoTitle">
                                        Workflow Name:
                                    </div>
                                    <div className="flowDataInfoInfo">
                                        {item.name}
                                    </div>
                                </div>
                                <div className="flowDataInfoWrapper">
                                    <div className="flowDataInfoTitle">
                                        Date Started:
                                    </div>
                                    <div className="flowDataInfoInfo">
                                        {UnixTimestampToDate(item.start)}
                                    </div>
                                </div>
                                <div className="flowDataInfoWrapper">
                                    <div className="flowDataInfoTitle">
                                        Workflow Length:
                                    </div>
                                    <div className="flowDataInfoInfo">
                                        {item.totalResult} Actions
                                    </div>
                                </div>
                                <div className="flowDataInfoWrapper">
                                    <div className="flowDataInfoTitle">
                                        Actions Remaining:
                                    </div>
                                    <div className="flowDataInfoInfo">
                                        {item.activeCount}
                                    </div>
                                </div>
                                <div className="buttonWrapper">
                                    <div className="accentButton" style={{ margin: 'auto', marginTop: '25px' }} onClick={() => stopFlow(item.theId)}>Stop Workflow</div>
                                </div>
                            </>
                        ))}
                    </div>
                </div>
            )}

            {newEvent && (
                <AddEvent from={from} setAppointment={setAppointment} calendar={calendar} setNewEvent={setNewEvent} agentTimezone={agentInfo.timezone} date={date} time={time} currentLead={lead[0]} />
            )}

        </>
    );
}

export default LeadCard;

//