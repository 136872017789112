import React, {useState, useEffect} from 'react';
import '../../css/activity.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import AppointmentDetail from '../team/detailComponents/appointments';
import CallbackDetail from '../team/detailComponents/callback';
import StatusChange from '../team/detailComponents/statusChange';
import Dials from '../team/detailComponents/dials';
import Applications from '../team/detailComponents/applications';
import { fetchWithDefaults } from '../../config/fetchDefault';
import { useSelector } from 'react-redux';

function AgentActivity({openLead, isMin, maxList}) {
    const isFeature984Enabled = process.env.REACT_APP_FUNNEL_FEATURE_984 === 'true'
    const [agentDetail, setAgentDetail] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const agentId=useSelector((state) => state.agent.userData).agentId;
    let calls=0;
    let contacts=0;

    const details= async (range)=>{
        const currentDate = new Date();
        const dateRange = new Date(currentDate);
        dateRange.setDate(currentDate.getDate() - range);

        const yyyymmdd = dateRange.toLocaleString('en-CA', {
            timeZone: 'America/Chicago',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        }).replace(/(\d+)-(\d+)-(\d+)/, '$1-$2-$3');

        // Calculate the date and time 7 days ago

        dateRange.setDate(currentDate.getDate() - range);
        dateRange.setHours(0, 0, 0, 0); // Set time to midnight

        // Get the Unix timestamp (in seconds) for the calculated date and time
        const unixTimestamp = Math.floor(dateRange.getTime() / 1000);
        try {
            const response = await fetchWithDefaults(`/activity/agentDetail?agent=${agentId}&unixTimestamp=${unixTimestamp}&yyyymmdd=${yyyymmdd}`);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();

            setAgentDetail(jsonData);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const getDaysDifference = (date1, date2) => {
        // Convert both dates to UTC to ensure accurate calculations
        if(date2==null){
            date2 = new Date();
        }

        const utcDate1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
        const utcDate2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
        // Calculate the difference in milliseconds
        const timeDifference = utcDate2 - utcDate1;

        // Convert the difference to days
        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

        details( daysDifference)
        setIsOpen(false);
    };


    const handleDateChange = (date, isStartDate) => {
      if (isStartDate) {
        setStartDate(date);
      } else {
        setEndDate(date);
      }
    };
    const openModule = () => {
        setIsOpen(!isOpen);
    };

    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const day = date.getDate().toString().padStart(2, '0');

        return `${month}/${day}`;
    }
    function formatUnix(unixTime) {
        const date = new Date(unixTime * 1000); // Convert seconds to milliseconds
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const day = date.getDate().toString().padStart(2, '0');

        return `${month}/${day}`;
    }
    useEffect(() => {
        // Fetch data when the component mounts
        details('0');
    }, []);
    agentDetail.length > 0 && agentDetail[0].dials.map((detail, index) => (
        calls=calls + parseInt(detail.callsMade),
        contacts=contacts + parseInt(detail.contacts)
    ))

    return (
        <div className="outerWrapper" style={{textAlign:'center'}}>
            <span className="outerWrapperTitle"><span className="outerWrapperIcon">
                </span>Activity</span>
            <div className="frontPageQuilityParentBlockWrapper" style={{overflow: 'visible',width:'70%'}}>
                <div className="quilityParentBlockHeader">
                    <div className="quilityParentBlockTitle">
                            Detailed Daily Activity Report
                        {/*<span className="typeBlock">{agentDetail.length} Activit{agentDetail.length > 1 ? 'ies' : 'y'}</span>*/}
                        <span className="typeBlock">{agentDetail.length > 0 ? calls : 0} Dial{calls > 1 || calls < 1 ? 's': ''}</span>
                        <span className="typeBlock">{agentDetail.length > 0 ? agentDetail[0].contacts : 0} Contact{agentDetail.length > 0 && agentDetail[0].contacts > 1 || agentDetail.length > 0 && agentDetail[0].contacts < 1 ? 's': ''}</span>
                        <span className="typeBlock">{agentDetail.length > 0 ? agentDetail[0].scheduled.length : 0} Appointment{agentDetail.length > 0 && agentDetail[0].scheduled.length > 1 || agentDetail.length > 0 && agentDetail[0].scheduled.length < 1 ? 's': ''}</span>
                    </div>
                </div>
                <div className="quilityParentBlockContent">
                <div className={`quilityNestedBlockWrapper`} style={{overflow: 'visible'}}>
                    <div className='quilityNestedBlockHeader'>
                        <span className="quilityParentBlockTitle">Search a Date Range</span>
                        <span className='quilityBlockIcon'></span>
                    </div>


                    <div className={`accentModuleContent ${isOpen ? 'openUp' : ''}`}>
                        <div className="pickerWrapper">
                            <div className="activityDatePickerHolder">
                                <div className="pickerTitle">Start Date:</div>
                                <DatePicker className="qualifierFieldInput" selected={startDate} onChange={(date) => handleDateChange(date, true)} />
                            </div>
                            <div className="activityDatePickerHolder">
                                <div className="pickerTitle">End Date:</div>
                                <DatePicker className="qualifierFieldInput" selected={endDate} onChange={(date) => handleDateChange(date, false)} />
                            </div>
                            <div className="buttonWrapper" style={{margin:'10px'}}>
                                <div className="accentButton solidQuilityActiveButton" onClick={()=>getDaysDifference(startDate,endDate)}>Search Dates</div>
                            </div>
                        </div>
                    </div>
                </div>

                    {agentDetail.length > 0 && isFeature984Enabled===true ? (
                        <>
                            <Dials details={agentDetail[0].dials} contacts={agentDetail[0].contacts}/>
                            <AppointmentDetail details={agentDetail[0].scheduled} isMin={isMin} maxList={maxList} openLead={openLead}/>
                            <CallbackDetail details={agentDetail[0].scheduledCalls} isMin={isMin} maxList={maxList} openLead={openLead}/>
                            <StatusChange details={agentDetail[0].statusChange} isMin={isMin} maxList={maxList} openLead={openLead}/>
                            <Applications details={agentDetail[0].applications} isMin={isMin} maxList={maxList} openLead={openLead}/>
                        </>
                    ) : agentDetail.length > 0 && isFeature984Enabled===false ? (
                        <>
                            <Dials formatDate={formatDate} details={agentDetail[0].dials} contacts={agentDetail[0].contacts}/>
                            <AppointmentDetail formatDate={formatUnix} details={agentDetail[0].scheduled} isMin={isMin} maxList={maxList} openLead={openLead}/>
                            <CallbackDetail formatDate={formatDate} details={agentDetail[0].scheduledCalls} isMin={isMin} maxList={maxList} openLead={openLead}/>
                            <StatusChange formatDate={formatUnix} details={agentDetail[0].statusChange} isMin={isMin} maxList={maxList} openLead={openLead}/>
                            <Applications formatDate={formatDate} details={agentDetail[0].applications} isMin={isMin} maxList={maxList} openLead={openLead}/>
                        </>
                    ) : (
                        null
                    )}
                </div>
            </div>
        </div>
    );
}

export default AgentActivity;