import { fetchWithDefaults } from '../config/fetchDefault';

const sendEmail = async (recipient, subject, value, agentId, first, last, setIsEmailOpen) => {
    try {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({
                agent:agentId,
                first:first,
                last:last,
                to:recipient,
                body:value,
                subject:subject,
            })
        };

        const response = await fetchWithDefaults('/google/gmail/send', requestOptions);

        if (!response.ok) {
            return false;
        }
        const jsonData = await response.json();
        setIsEmailOpen(false);
    }
    catch (error) {
        console.error("Error fetching data:", error);
        return false;
    }
};


// the regex used here comes from: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#basic_validation
const validateEmailFormat = (email) => {
    const isset = email !== undefined && email !== null
    const isValidFormat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(email)

    return isset && isValidFormat
}
export {sendEmail,validateEmailFormat};