import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import Icon from "../../../../assets/icons/icon";
import "../../../../css/signup.css"; // using the error styles
import { validateEmailFormat } from "../../../../utilities/emailFunctions";
import { getFormattedDate } from "../../../../utilities/timeFunctions";

function AddEvent({
  from,
  date,
  time,
  setNewEvent,
  currentLead,
  setAppointment,
}) {
  const scopes = useSelector((state) => state.scopes.scopes)[0];
  const reminders = useSelector((state) => state.reminders.reminders);
  const calendarBeingUsed = useSelector((state) => state.agent.activeCalendar);
  const agentInfo = useSelector((state) => state.agent.userData);
  const [meetDate, setMeetDate] = useState(date);
  const [meetTime, setMeetTime] = useState(time);
  const [videoType, setVideoType] = useState(false);
  const [error, setError] = useState({});
  const timezones = [
    { name: "Pacific Time", value: "America/Los_Angeles" },
    { name: "Arizona Time", value: "America/Phoenix" },
    { name: "Mountain Time", value: "America/Denver" },
    { name: "Central Time", value: "America/Chicago" },
    { name: "Eastern Time", value: "America/New_York" },
    { name: "Alaska Time", value: "America/Anchorage" },
    { name: "Hawaii Time", value: "Pacific/Honolulu" },
    { name: "Puerto Rico Time", value: "America/Puerto_Rico" },
  ];
  const convertDateFormat = (dateString) => {
    const parts = dateString.split("/");
    return `${parts[2]}-${parts[0].padStart(2, "0")}-${parts[1].padStart(
      2,
      "0"
    )}`;
  };

  const [wholeEvent, setWholeEvent] = useState({
    Description: "",
    Location: "",
    Organizer: "",
    Start: convertDateFormat(date) + "T" + convertSmallStamp(time),
    Status: "",
    Summary:
      "Meeting With " +
      currentLead.borrower_first +
      " " +
      currentLead.borrower_last,
    participant: [currentLead.email],
    attendees: [
      {
        email: currentLead.email,
      },
    ],
    duration: 60,
    timezone: agentInfo.timezone,
    lead: currentLead.lead_id,
    calendar: calendarBeingUsed,
    team: agentInfo.teamId,
    reminder: "general",
  });
  const handleSubmit = () => {
    setError({});
    const errors = {};
    if (!wholeEvent.Summary) {
      errors.summary = "Meeting title is required";
    }
      if (!wholeEvent.duration) {
        errors.duration = "Duration is required";
      } else if (wholeEvent.duration && !/^[0-9]+$/.test(wholeEvent.duration)) {
        errors.duration = "Only numbers are required";
      } else if (wholeEvent.duration && /^[0-9]+$/.test(wholeEvent.duration) && Number(wholeEvent.duration) < 15){
        errors.duration = "Minimum 15 is required";
      }
      if(new Date(wholeEvent.Start) < new Date()){
        errors.dateTime = "Required selected date/time needs to be later than the current date/time"
      }
      if(wholeEvent.attendees.length && currentLead.email){
        let email = wholeEvent.attendees.find(item=>item?.email?.toLowerCase() === currentLead.email.toLowerCase())
        if (!email){
            errors.attendees = "One of the participant should be a Lead"
        }
      }
    setError(errors);
    if (Object.keys(errors).length > 0) return;
    setAppointment(wholeEvent, videoType);
  };

  const addVideo = (type) => {
    if (type === videoType) {
      setVideoType(false);
    } else {
      setVideoType(type);
    }
  };
  const handleInputChange = (fieldName, value) => {
    setWholeEvent((prevEvent) => ({
      ...prevEvent,
      [fieldName]: value,
    }));
  };

  const handleParticipantChange = (attendee, index) => {
    let attendeeChange = [...wholeEvent.attendees];
    attendeeChange[index] = { email: attendee };

    setWholeEvent((prevState) => ({
      ...prevState,
      attendees: attendeeChange,
    }));
  };

  const addNotes = (type) => {
    setWholeEvent((prevEvent) => ({
      ...prevEvent,
      Description: prevEvent.Description
        ? prevEvent.Description + " " + currentLead[type]
        : currentLead[type],
    }));
  };
  const addAddress = () => {
    setWholeEvent((prevEvent) => ({
      ...prevEvent,
      Location: prevEvent.Location
        ? prevEvent.Location +
          " " +
          currentLead.address +
          " " +
          currentLead.city +
          " " +
          currentLead.state +
          " " +
          currentLead.zip
        : currentLead.address +
          " " +
          currentLead.city +
          " " +
          currentLead.state +
          " " +
          currentLead.zip,
    }));
  };

  function splitDateTime(dateTimeString) {
    const [datePart, timePart] = dateTimeString.split("T");
    return {
      date: datePart,
      time: timePart ? timePart.split("-")[0] : "",
    };
  }
  const generateTimeSlots = () => {
    const timeSlots = [];
    const startTime = new Date();
    startTime.setHours(2, 0, 0, 0); // Set to midnight
    const endTime = new Date();
    endTime.setHours(23, 45, 0, 0); // Set to 11:45 PM

    const interval = 15 * 60 * 1000; // 15 minutes in milliseconds

    let currentTime = new Date(startTime);
    while (currentTime <= endTime) {
      timeSlots.push(currentTime);
      currentTime = new Date(currentTime.getTime() + interval);
    }

    return timeSlots;
  };
  const formatTime = (time) => {
    const hours = time.getHours();
    const minutes = time.getMinutes();
    const amOrPm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert 0 to 12
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedHours}:${formattedMinutes} ${amOrPm}`;
  };
  function convertTo24HourTime(dateString) {
    const date = new Date(dateString);
    const formattedTime = date.toLocaleTimeString("en-US", { hour12: false });
    return formattedTime;
  }
  const handleCancel = () => {
    setNewEvent(false);
  };
  const changeZone = (zone) => {
    setWholeEvent((prevEvent) => ({
      ...prevEvent,
      timezone: zone, // Update the specific field in the wholeEvent object
    }));
  };
  const changeReminder = (reminder) => {
    setWholeEvent((prevEvent) => ({
      ...prevEvent,
      reminder: reminder, // Update the specific field in the wholeEvent object
    }));
  };
  function convertSmallStamp(timeString) {
    // Split the time string into hours, minutes, and AM/PM
    const [time, period] = timeString.split(" ");
    const [hours, minutes] = time.split(":").map((num) => parseInt(num));

    // Convert hours to 24-hour format
    let hours24 = hours;
    if (period === "PM" && hours !== 12) {
      hours24 += 12;
    } else if (period === "AM" && hours === 12) {
      hours24 = 0;
    }

    // Pad hours and minutes with leading zeros if necessary
    const formattedHours = String(hours24).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");

    // Return the time in 24-hour format
    return `${formattedHours}:${formattedMinutes}:00`;
  }
  const updateTime = (time) => {
    const dateStr = convertDateFormat(meetDate) + "T" + convertSmallStamp(time);
    setMeetTime(time);
    setWholeEvent((prevEvent) => ({
      ...prevEvent,
      ["Start"]: dateStr,
    }));
  };
  const convertDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${month}/${day}/${year}`;
  };
  const updateDate = (date) => {
    const dateStr = date + "T" + convertSmallStamp(meetTime);
    const convertedDate = convertDate(date);
    setMeetDate(convertedDate);
    setWholeEvent((prevEvent) => ({
      ...prevEvent,
      ["Start"]: dateStr,
    }));
  };
  const handleAddParticipant = () => {
    setWholeEvent((prevState) => ({
      ...prevState,
      attendees: [...wholeEvent.attendees, { email: "" }],
    }));
  };
  const removeParticipant = (indexToRemove) => {
    const newArray = wholeEvent.attendees.filter(
      (_, index) => index !== indexToRemove
    );
    setWholeEvent((prevState) => ({
      ...prevState,
      attendees: newArray,
    }));
    validateEmailFormatOnChange();
  };

  const [emailCheck, setEmailCheck] = useState(false); // true - invalid, false - valid

  const validateEmailFormatOnChange = () => {
    let invalidEmail = false;
    for (let index = 0; index < wholeEvent.attendees.length; index++) {
      const { email } = wholeEvent.attendees[index];

      if (!validateEmailFormat(email)) {
        invalidEmail = true;
        break;
      }
    }

    setEmailCheck(invalidEmail);
  };

  return (
    <div
      className={`eventWindow ${from === "session" ? "sessionEvent" : "asdf"}`}
    >
      <div className="eventItemWrapper">
        <div className="quilityCloseWindow" style={{ top: "5px" }}>
          <span
            className="fontello"
            style={{ marginTop: "-7px" }}
            onClick={() => handleCancel()}
          >
            <Icon
              name={"x-circle-q"}
              size={34}
              className="closeIcon"
              adjust="fontello"
            />
          </span>
        </div>
        <div className="editEventItemTitle">Meeting Title</div>
        <input
          type="text"
          className="eventInput"
          value={wholeEvent.Summary}
          onChange={(e) => handleInputChange("Summary", e.target.value)}
        />
        {error.summary && <div className="fieldError">{error.summary}</div>}
      </div>
      <div className="eventItemWrapper">
        <div className="editEventItemTitle">Timezone</div>
        <select
          className="eventInput"
          onChange={(e) => changeZone(e.target.value)}
        >
          {timezones.map((zone, index) => (
            <option
              key={`timezones${index}`}
              value={zone.value}
              selected={agentInfo.timezone === zone.value}
            >
              {zone.name}
            </option>
          ))}
        </select>
      </div>
      <div className="eventItemWrapper">
        <div className="editEventItemTitle">
          Location{" "}
          <div className="addWrapper">
            <div className="addNotesIcon" onClick={() => wholeEvent.Location ? null : addAddress()}>
              +Add
            </div>
          </div>
        </div>
        <input
          type="text"
          className="eventInput"
          value={wholeEvent.Location}
          onChange={(e) => handleInputChange("Location", e.target.value)}
        />
      </div>
      <div className="eventItemWrapper">
        <div className="editEventItemTitle">Participants</div>{" "}
        <div className="addEventParticipantWrapper">
          <div
            className="addEventParticipant"
            onClick={() => handleAddParticipant()}
          >
            Add Participant
          </div>
        </div>
        <div className="participantsHolder">
          {wholeEvent.attendees.map((attendee, index) =>
            index === 0 ? (
              <input
                type="email"
                key={`attendee${index}`}
                className="eventInput"
                value={attendee.email}
                onChange={(e) => {
                  handleParticipantChange(e.target.value, index);
                  validateEmailFormatOnChange();
                }}
              />
            ) : (
              <span style={{ position: "relative" }} key={`attendee${index}`}>
                <input
                  type="email"
                  className="eventInput"
                  value={attendee.email}
                  onChange={(e) => {
                    handleParticipantChange(e.target.value, index);
                    validateEmailFormatOnChange();
                  }}
                />
                <div
                  className="iconWrapper2"
                  onClick={() => removeParticipant(index)}
                >
                  <span className="fontello" style={{ marginTop: "-10px" }}>
                    <Icon
                      name={"x-circle-q"}
                      size={34}
                      className="closeIcon"
                      adjust="fontello"
                    />
                  </span>
                </div>
              </span>
            )
          )}
          {emailCheck && (
            <div
              className="conentBodyInputError"
              style={{ textAlign: "center" }}
            >
              Invalid email in participants list.
            </div>
          )}
        </div>
        {error.attendees && <div className="fieldError">{error.attendees}</div>}
      </div>
      <div className="eventItemWrapper">
        <div className="editEventItemTitle">Video Options</div>
        <div className="buttonWrapper">
          {scopes && scopes.zoom && (
            <div
              className={`dataButton ${videoType === "zoom" ? "active" : ""}`}
              onClick={() => addVideo("zoom")}
            >
              Zoom Meeting
            </div>
          )}
          {scopes &&
            scopes.google.includes(
              "https://www.googleapis.com/auth/calendar.events"
            ) && (
              <div
                className={`dataButton ${
                  videoType === "google" ? "active" : ""
                }`}
                onClick={() => addVideo("google")}
              >
                Google Meet
              </div>
            )}
        </div>
      </div>
      <div className="eventItemWrapper">
        <div className="editEventItemTitle">Reminder Options</div>
        <div className="buttonWrapper">
          <select
            className="eventInput"
            onChange={(e) => changeReminder(e.target.value)}
          >
            <option value="general" key={`reminder00`}>
              Select A Reminder Profile
            </option>
            {reminders.map((reminder, index) => (
              <option value={reminder.id} key={`reminder${index}`}>
                {reminder.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="eventItemWrapper">
        <div className="editEventItemTitle" style={{ dispaly: "inline-block" }}>
          Meeting Comments
          <div className="addWrapper">
            <div className="addNotesIcon2" onClick={() => addNotes("notes")}>
              + Notes
            </div>
            <div className="addNotesIcon2" onClick={() => addNotes("data")}>
              + Data
            </div>
            <div className="addNotesIcon2" onClick={() => addNotes("game")}>
              + Plan
            </div>
          </div>
        </div>
        <textarea
          type="text"
          className="eventInput eventTextarea"
          value={wholeEvent.Description}
          onChange={(e) => handleInputChange("Description", e.target.value)}
        />
      </div>
      <div className="eventItemWrapper">
        <div className="editEventItemTitle">Meeting Date & Time</div>
        <input
          type="date"
          className="eventInput halfSize"
          value={splitDateTime(wholeEvent.Start).date}
          onChange={(e) => updateDate(e.target.value)}
          style={{ width: "30%" }}
          min={getFormattedDate()}
        />
        <span className="spacer">@</span>
        <select
          className="timeSelect"
          onChange={(e) => updateTime(e.target.value)}
        >
          {generateTimeSlots().map((time, index) => {
            const selected =
              convertTo24HourTime(time) ===
              splitDateTime(wholeEvent.Start).time;
            return (
              <option
                key={index}
                value={convertTo24HourTime(time)}
                selected={selected}
              >
                {formatTime(new Date(time))}
              </option>
            );
          })}
        </select>
      </div>
      {error.dateTime && <div className="fieldError">{error.dateTime}</div>}
      <div className="eventItemWrapper">
        <div className="editEventItemTitle">Duration</div>
        <input
          type="text"
          className="eventInput"
          value={wholeEvent.duration}
          onChange={(e) => handleInputChange("duration", e.target.value)}
          style={{ width: "75px" }}
        />
        {error.duration && <div className="fieldError">{error.duration}</div>}
        <div style={{ marginTop: "10px", marginBottom: "-20px" }}>
          <span className="spacer" style={{ padding: "5px 10px" }}>
            <b>Minutes</b>
          </span>
        </div>
      </div>
      <div
        className="buttonWrapper"
        style={{ textAlign: "center", padding: "40px" }}
      >
        <div
          className="standardButton cancelButton solidQuilityDestructiveButton"
          onClick={() => handleCancel()}
        >
          Cancel
        </div>
        <div
          className={`standardButton ${
            emailCheck === true
              ? "saveButtonInactive solidQuilityInactiveButton"
              : "saveButton solidQuilityActiveButton"
          }`}
          onClick={() => handleSubmit()}
        >
          Schedule
        </div>
        {emailCheck && (
          <div className="conentBodyInputError" style={{ textAlign: "center" }}>
            Invalid email in participants list.
          </div>
        )}
      </div>
    </div>
  );
}

export default AddEvent;
