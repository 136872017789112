import { fetchWithDefaults } from '../config/fetchDefault';
import moment from 'moment-timezone';
import { setScopes } from '../features/scopesSlice';

async function gatherCalendars (agentId){
    try {
        const response = await fetchWithDefaults(`/google/calendars?agent=${agentId}`);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        return(jsonData);
    } 
    catch (error) {
        console.error('Error fetching data:', error); 
    }
}
function convertSmallStamp(timeString) {
    // Split the time string into hours, minutes, and AM/PM
    const [time, period] = timeString.split(' ');
    const [hours, minutes] = time.split(':').map(num => parseInt(num));
    // Convert hours to 24-hour format
    let hours24 = hours;
    if ((period === 'PM' || period === 'pm') && hours !== 12) {
        hours24 += 12;
    } else if (period === 'AM' && hours === 12) {
        hours24 = 0;
    }

    // Pad hours and minutes with leading zeros if necessary
    const formattedHours = String(hours24).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');

    // Return the time in 24-hour format
    return `${formattedHours}:${formattedMinutes}:00`;
}
function convertToTimezone(dateString, targetTimezone) {
  // Parse the date string and convert to the target timezone
  const originalMoment = moment.parseZone(dateString);
  
  // Get the equivalent UTC time in the target timezone
  const targetTime = originalMoment.clone().tz(targetTimezone).format('YYYY-MM-DDTHH:mm:ss');
  
  // Reapply the original offset to the target time
  const adjustedMoment = moment.parseZone(`${targetTime}${originalMoment.format('Z')}`);
  
  // Return the adjusted date with the original offset
  return adjustedMoment.format('YYYY-MM-DDTHH:mm:ssZ');
}  
const convertDateFormat = (dateString) => {
    const parts = dateString.split('/');
    return `${parts[2]}-${parts[0].padStart(2, '0')}-${parts[1].padStart(2, '0')}`;
};
const cancelGoogleEvent = async (eventId,calendarId,agentId,handleCancel,setUpdate) => {
    handleCancel();
    try {
        const response = await fetchWithDefaults(`/google/delete/event?agent=${agentId}&eventId=${eventId}&calendarId=${calendarId}`, {
            method: 'DELETE'
        });
    
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        if(jsonData==(true)){
            setUpdate(eventId);
        };
        // Handle the response data here if needed
    } 
    catch (error) {
        console.error('Error fetching data:', error); 
    }
};
function openAuthPopup(agentId, name, dispatch) {
    const options = [
        'width=500',
        'height=600',
        'left=100',
        'top=100',
        'resizable=yes',
        'scrollbars=yes',
        'status=no'
      ].join(',');
    const backend_port = process.env.REACT_APP_BACKEND_PORT ? process.env.REACT_APP_BACKEND_PORT : 3333
    const url=`https://${window.location.hostname}:${backend_port}/google/auth?agent=${agentId}`;
    return new Promise((resolve, reject) => {
        const popup = window.open(url, name, options);
        if (!popup) {
            return reject(new Error('Popup blocked'));
        }
  
        const popupTick = setInterval(() => {
            try {
            if (popup.closed) {
                clearInterval(popupTick);
                return reject(new Error('Popup closed'));
            }
    
            if (popup.location.href.startsWith(window.location.origin)) {
                clearInterval(popupTick);
                const params = new URLSearchParams(popup.location.search);
                const status=params.get('status');
                const scopes=params.get('scopes');
                if(status==='complete'){
                    dispatch(setScopes([{"google": scopes,"zoom": false}]))
                }
                popup.close();
                resolve(true);
            }
            } catch (error) {
            // Ignore cross-origin errors
            }
        }, 500);
    });
}
async function reAuth(agent) {
     
}

export {gatherCalendars, convertToTimezone, convertSmallStamp, convertDateFormat,cancelGoogleEvent,openAuthPopup,reAuth};