import React from 'react';
import Icon from '../../assets/icons/icon';
import { useSelector, useDispatch } from 'react-redux';
import * as images from '../../assets/icons/carrier_logos/index';
import { handleSelectedProducts, sendLeadToNavigator } from '../../utilities/virtualMentor';
import { setLoadingGif } from '../../features/loadingSlice';

function ReccomendationBlock({from,reccomendation, setSelectedProducts, setExtraInfo, lead, possibleConditions, faceAmount, selectedProducts}) {
    const isFeature133Enabled = process.env.REACT_APP_FUNNEL_FEATURE_133 === 'true'
    const agentInfo=useSelector((state) => state.agent.userData);
    const dispatch = useDispatch()

   /* function normalizeCapitalized(str) {
        return str
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(' ');
    }
    const findVmIdById = (searchId) => {
        for (let item of possibleConditions) {
            let questions;
            try {
                questions = JSON.parse(item.question);
            } catch (error) {
                console.error("Failed to parse question field:", item.question, error);
                continue; // Skip this item if parsing fails
            }

            if (Array.isArray(questions)) {
                for (let question of questions) {
                    if (question.id === searchId) {
                        return {id:item.vm_id,name:item.search_name,navigator:item.navigator};
                    }
                }
            }
        }
        return null;
    };
    const createNavigatorObject = (arr,lead) => {

        const result = {
            firstName:lead.borrower_first,
            lastName:lead.borrower_last,
            assignedToUserId:'',
            agentEmail:agentInfo.email,
            agentCode:'',
            phoneMobile:lead.borrower_cell || lead.borrower_home || lead.borrower_work,
            email:lead.email,
            address:lead.address,
            city:lead.city,
            zip:lead.zip,
            amount:'',
            term:'',
            gender:lead.borrower_sex,
            birthDate:lead.dob,
            state:lead.state,
            LeadCode:lead.lead_code
        };
        for (let item of arr) {
            if (item.navigator) {
                result[item.navigator] = true;
            }
        }
        return result;
    };*/
    async function sendToNavigator(lead) {
        if(isFeature133Enabled === true){
            // in case we can not push the lead data into navigator
            const fallbackUrl = "https://dynamic-advisor-prod.techf.com/login?org=org_VXB5UCMciyy4LpyU&connection=QuilitySSO";
            // window.open(url, "_blank");

            dispatch(setLoadingGif(true));
            const navAgentClientUrl = await sendLeadToNavigator(agentInfo.agentCode, agentInfo.email, lead[0].lead_code, lead[0].borrower_age, lead[0].borrower_sex, dispatch)

            if(navAgentClientUrl === null){
                console.log(`[ReccomendationBlock-sendToNavigator] Redirecting to ${fallbackUrl}`)
                window.open(fallbackUrl, "_blank");
            } else {
                console.log(`[ReccomendationBlock-sendToNavigator] Redirecting to ${navAgentClientUrl}`)
                window.open(navAgentClientUrl, "_blank");
            }
        } else {
            const url = "https://dynamic-advisor-prod.techf.com/login?org=org_VXB5UCMciyy4LpyU&connection=QuilitySSO";
            window.open(url, "_blank");
        }
    }
    const checkSelected = (tempId) => {
        if(selectedProducts){
            return selectedProducts.some(item => item.product.tempId === tempId);
        }
        else{
            return false;
        }
    };

    return (
        <div className={`recBlock ${checkSelected(reccomendation.tempId) && 'selectedRec'}`} onClick={()=> from==='script' ? null : handleSelectedProducts(faceAmount,reccomendation,setSelectedProducts)}>
            <div className="recTop">
                <div className="recLogoHolder">
                <img src={images.default[`${reccomendation.organization}`]} className="recLogo" />
                </div>
                <div className="recTitle">{reccomendation.name}</div>
                {reccomendation.quotes.length > 0 ? (
                    <div className="recQuote">${reccomendation.quotes[0].MonthlyPremium}</div>
                ) : (
                    <div className="recQuote" style={{fontSize: '15px'}}>Not Available</div>
                )}

            </div>
            <div className="recBottom">
                <div className="recLink outlineQuilityActiveButton" onClick={(e)=>[setExtraInfo(reccomendation),e.stopPropagation()]}>
                    <a className="noDeco">Info</a>
                </div>
                <div className="recLink solidQuilityActiveButton">
                    {reccomendation.application_url === 'https://dynamic-advisor-prod.techf.com/login?org=org_VXB5UCMciyy4LpyU&connection=QuilitySSO' ? (
                        <span onClick={()=>sendToNavigator(lead)}>Apply <span className="fontello" style={{lineHeight: '12px'}}><Icon name={'link-external-q'} size={14} height="22" adjust="fontello" /></span></span>
                    ) : (
                        <a className="noDeco" href={reccomendation.application_url} target='_blank'>Apply <span className="fontello" style={{lineHeight: '12px'}}><Icon name={'link-external-q'} size={14} height="22" adjust="fontello" /></span></a>
                    )}

                </div>
                <div className="recLink solidQuilityActiveButton">
                    <a className="noDeco" href={reccomendation.quote_url} target='_blank'>Quote <span className="fontello" style={{lineHeight: '12px'}}><Icon name={'link-external-q'} size={14} height="22" adjust="fontello" /></span></a>
                </div>
            </div>
        </div>

    );
}

export default ReccomendationBlock;