// Import JSON file
import glyphs from './glyphs.json';

// Icon component
// const Icon = ({ height, name, adjustSize, size = 24, color = 'currentColor',className, adjust, viewBox='0 0 1024 1024', pathColor }) => {
//   const glyph = glyphs.find(g => g.name === name);
//   if (!glyph) return null;

//   return (
//     <svg
//       className={className}
//       width={adjustSize ? adjustSize : size}
//       height={height ? height : 30}
//       viewBox={viewBox}
//       fill={color}
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path d={glyph.path} fill={pathColor ?? ''}/>
//     </svg>
//   );
// };

let Icon
const isFeature580Enabled = process.env.REACT_APP_FUNNEL_FEATURE_580 === 'true'

if(process.env.REACT_APP_FUNNEL_FEATURE_875==='true') {
    Icon = ({ height, name, adjustSize, size = 24, color = 'currentColor',className, adjust, viewBox='0 0 1024 1024', pathColor }) => {
          const glyph = glyphs.find(g => g.name === name);
          if (!glyph) return null;

          return (
            <svg
              className={className}
              width={adjustSize ? adjustSize : size}
              height={height ? height : 30}
              viewBox={isFeature580Enabled ? viewBox : '0 0 1024 1024'}
              fill={color}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d={glyph.path} fill={pathColor ?? ''}/>
            </svg>
          );
        };
} else {
    Icon = ({ height, name, adjustSize, size = 24, color = 'currentColor',className, adjust, viewBox='0 0 1024 1024' }) => {
        const glyph = glyphs.find(g => g.name === name);
        if (!glyph) return null;

        return (
          <svg
            className={className}
            width={adjustSize ? adjustSize : size}
            height={height ? height : 30}
            viewBox={isFeature580Enabled ? viewBox : '0 0 1024 1024'}
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d={glyph.path} />
          </svg>
        );
      };
}

export default Icon;
