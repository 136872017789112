
const canMessageCurrentLeadTCPA = (_lead, conversations) => {
    let convoCount = 0
    const isFeature1033Enabled = process.env.REACT_APP_FUNNEL_FEATURE_1033 === 'true'

    if(isFeature1033Enabled===true){
        for (let i = 0; i < conversations.length; i++) {
            const entries = Object.entries(conversations[i])

            for (let j = 0; j < entries.length; j++) {
                const [_key, convoList] = entries[j]
                convoCount += convoList.length
            }
        }

        return convoCount > 0
    }

    return conversations.length !== undefined && conversations.length > 0
}

export {canMessageCurrentLeadTCPA}