import React, {useState} from 'react';
import Icon from '../../../assets/icons/icon';
import { UnixTimestampToDate } from '../../../utilities/timeFunctions';

function AppointmentDetail({details,formatDate,openLead}) {
    const isFeature984Enabled = process.env.REACT_APP_FUNNEL_FEATURE_984 === 'true'
    const [open, setOpen] = useState(false);
    const openUp=()=>{
        setOpen((prevOpen) => !prevOpen);
    }

    return (

            <div className="quilityNestedBlockWrapper" onClick={(event) => { event.stopPropagation();openUp();}}>
                <div className='quilityNestedBlockHeader'>
                    <div className="quilityParentBlockTitle" style={{width: '170px'}}>Appointments</div>
                    <span style={{marginLeft:'20px'}}>
                        <span className="typeBlock">{details.length} Appointment{details.length > 1 || details.length < 1 ? 's': ''}</span>
                    </span>
                    <span className={`quilityBlockIcon ${open ? 'rotate':''}`} style={{marginTop: '-5px', transition:'all .5s'}}><span className="fontello"><Icon name={'q-up'} size={20} className="closeIcon" adjust="fontello" /></span></span>
                </div>

                <div className={`detailWrapper ${open ? 'openUp' : ''}`}>
                    {details.map((detail, index) => (
                        <div className="detailItem" key={"appointment"+index}>
                            <span className="detailTitle">{detail.apptTitle}</span>
                            <span className="detailDate">{isFeature984Enabled ? UnixTimestampToDate(detail.apptDate) : formatDate(detail.apptDate)}</span>
                            <span className="detailTimezone">{detail.apptTimezone}</span>
                            <span className="ViewLead" onClick={(event) => { event.stopPropagation(); openLead(detail.apptLead); }}>View Lead</span>
                        </div>
                    ))}
                </div>
            </div>

    );
}

export default AppointmentDetail;