import "../../css/tooltip.css"

const Tooltip = ({title, body, direction='top', customWrapperClass, customTipClass, customContentClass, customContentTitleClass, customContentBodyClass}) => {
  return (
    <div className={customWrapperClass ?? "Tooltip-Wrapper"}>
        <div className={`${customTipClass ?? 'Tooltip-Tip'} ${direction}`}>
            <div className={customContentClass ?? "Tool-Tip-Content"}>
                {title && title.length > 0 && (
                    <div className={customContentTitleClass ?? "Tool-Tip-Content-Title"}>{title}</div>
                )}

                {body && body.length && (
                    <div className={customContentBodyClass ?? "Tool-Tip-Content-Body"}>{body}</div>
                )}
            </div>
        </div>
    </div>
  );
};

export default Tooltip;