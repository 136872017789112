import React, {useState,useRef, useEffect} from 'react';
import '../css/block.css';
import Icon from '../assets/icons/icon';

function Block({title,data,fetchPage, icon, setWhich, which, expanded}) {
    const isFeature971Enabled = process.env.REACT_APP_FUNNEL_FEATURE_971==='true'
    const [isOpen, setIsOpen] = useState(false);
    const [expandedHeight, setExpandedHeight] = useState(null)
    const innerWrapperRef = useRef(null);
    function fetch(path){
        fetchPage(path);
    }
    const renderIcon=(icon)=>{
        return <span className="fontello"><Icon name={icon} size={20} className="" adjust="fontello" /></span>
    }
    const handleOpen=()=>{
        setIsOpen(!isOpen);
    }
    const getHeight = () => {
        if (innerWrapperRef.current) {
            return innerWrapperRef.current.clientHeight;
        }
        return 0;
    };
    const handleSetWhich=(which)=>{
        setWhich(which);
    }

    useEffect(()=>{
        if(isFeature971Enabled===true){
            const innerHeight = getHeight()
            setExpandedHeight(`${innerHeight}px`)
        }
    }, [])

    return (
        <div id="blockWrapper" className={`${which===title && 'selectedOption'}`} style={{ height: isFeature971Enabled===true ? ((isOpen && expanded) ? expandedHeight : '40px') : (isOpen ? getHeight()+50 : '40px') }} onClick={()=>handleSetWhich(title)}>

            {data.length < 2 ?(
                <>
                    <div className="blockTitle" onClick={() => fetch(data[0].path)}><span className={`leftBarIconHolder ${which===title && 'adjustIcon'}`}>{renderIcon(icon)}</span>{title}</div>
                    <div className="optionsHolder"></div>
                </>
            ):(
                <>
                    <div className="blockTitle" onClick={()=>handleOpen()}><span className="leftBarIconHolder">{renderIcon(icon)}</span>{title} <span className={`additionalItemIcon ${isOpen ? 'rotateIcon':''}`}><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'q-up'} size={20} className="closeIcon" adjust="fontello" /></span></span></div>
                    <div className="optionsHolder" >
                        <div className="leftInnerWrapper" ref={innerWrapperRef}>
                            {data.map((item, index) => (
                                <div className="menuOption" key={`randomBlock${index}`} path={item.path} title={title} onClick={()=>{fetch(item.path);handleOpen()}}>{item.name}</div>
                            ))}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default Block;