import { io } from 'socket.io-client';

let socket;

export const getSocket = () => {
    if (!socket) {
        const backend_port = process.env.REACT_APP_BACKEND_PORT ? process.env.REACT_APP_BACKEND_PORT : 3333
        socket = io(`https://${window.location.hostname}:${backend_port}`);
    }
    return socket;
};

export const addSocketListener = (eventName, callback) => {
    const socket = getSocket();
    socket.on(eventName, callback);
};

export const removeSocketListener = (eventName) => {
    const socket = getSocket();
    socket.off(eventName);
};