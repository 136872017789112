import { createSlice } from "@reduxjs/toolkit";

export const conversationSlice = createSlice({
  name: "conversations",
  initialState: {
    conversations: [],
    convoWindow: false,
    currentConvoId: false,
  },
  reducers: {
    setConversations(state, action) {
      state.conversations = action.payload;
    },
    addConversation(state, action) {
      state.conversations.push(action.payload);
    },
    updateConversation(state, action) {
      const { convo_id, updatedConversation } = action.payload;
      const index = state.conversations.findIndex(
        (conversation) => conversation.convo_id === convo_id
      );
      if (index !== -1) {
        state.conversations[index] = updatedConversation;
      }
    },
    setConvoId(state, action) {
      state.currentConvoId = action.payload;
    },
    setConvoWindow(state, action) {
      state.convoWindow = action.payload;
    },
  },
});
export const {
  setConversations,
  addConversation,
  updateConversation,
  setConvoWindow,
  setConvoId,
} = conversationSlice.actions;

export default conversationSlice.reducer;
