import Intercom from "@intercom/messenger-js-sdk";
import { fetchWithDefaults } from "../config/fetchDefault"

const initIntercom = async (agentId) => {
    const res = await fetchWithDefaults(`/agents/intercomData?agent=${agentId}`)
    const {app_id, user_id, user_hash, name, email, created_at} = await res.json()

    Intercom({app_id, user_id, user_hash, name, email, created_at, custom_domain: window.location.hostname,})
}

export {initIntercom}