import React, { useState } from "react";
import '../../css/bulkActionFooter.css';
import { useSelector, useDispatch } from 'react-redux';
import { fetchWithDefaults } from '../../config/fetchDefault';
import Warning from "../warning";
import { SuccessAlert } from "../successAlert";
import { setQueue } from '../../features/queueSlice';


function BulkActionFooter({ selectedLeads, setSelectedLeads, size, textingNumbers, jumpIntoQueue, setRefreshQueue}) {
    const dispatch=useDispatch();
    const [applyClassArray, setApplyClassArray] = useState(false); // State to track whether to apply the class for each choice
   // const [amountSelected, setAmountSelected] = useState(0); // Assuming this state is already defined
    const agentInfo=useSelector((state) => state.agent.userData);
    const workflows=useSelector((state) => state.workflows.workflows);
    const convoFlow=useSelector((state) => state.convoFlow.convoFlow);
    const status=useSelector((state) => state.status.status);
    const texts=useSelector((state) => state.texts.texts);
    const tags=useSelector((state) => state.tags.tags);
    const queue=useSelector((state) => state.queue.queue);
    const [successTrigger, setSuccessTrigger] = useState(false);
    
    const [errorMessage, setErrorMessage] = useState(false);
    const [warning, setWarning] = useState(false);

    const handleIconClick = (index) => {
        setApplyClassArray(index);
    };
    const closeModal=()=>{
        setApplyClassArray(false)
    }
    const buttons=[
        {icon:'', short:'Dial',label:""},
        {icon:'', short:'Text', label:"", search:'text_template',values:[texts]},
        {icon:'', short:'Tag', label:"", search:'tags', values:[tags]},
        {icon:'', short:'Status', label:"",search:'lead_status',values:[status]},
        {icon:'', short:'Workflow', label:"",search:'workflow',values:[workflows]},
    ]
    const bulk= async (id, type)=>{

        const list= selectedLeads;
        let numbers='';
        let numberWarning=false;
        //this object doesn't have to look like this, but the backend is created to allow multple bulk actions at one time so it needs to have this structure
        const action=[{[type]: [id]}];
        if(textingNumbers.length < 1){
            const textingthings = type==='text_template' || type==='workflow' || type==='conversation';
            console.log(textingthings);
            if(textingthings){
                setErrorMessage("You do not have any text compliant numbers, so you cannot add to a workflow or guided flow")
                numberWarning=true;
            }
        }
        else{
            numbers=textingNumbers;
        }
        if (!numberWarning || numbers !== '') {
            if(list.length < 100){
                try {
                    const requestOptions = {
                        method: 'POST',
                        body: JSON.stringify({ 
                            leads: list,
                            actions:action,
                            agent:agentInfo.agentId,
                            numbers:textingNumbers
                        }) 
                    };
                    const response = await fetchWithDefaults('/bulkOptions', requestOptions);
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const jsonData = await response.json();
                    //setSelectedLeads([]);
                    //setBulkGroups([]);
                    setSuccessTrigger(true);
                    closeModal();
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
            else{
                setWarning({length:list.length, action:action});
            }
        }
    }
    const closeAlert=()=>{
        closeModal();
        setErrorMessage(false);
        setWarning(false);
    }
    const sendBulk= async ()=>{
        closeAlert();
        try {
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({ 
                    leads: selectedLeads,
                    actions:warning.action,
                    agent:agentInfo.agentId,
                    numbers:textingNumbers
                }) 
            };
            const response = await fetchWithDefaults('/bulkOptions', requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            //setSelectedLeads([]);
            //setBulkGroups([]);
            setSuccessTrigger(true);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const findLeadsByIds = (queue, leadIds) => {
        return leadIds.map(leadId => queue.find(lead => lead.lead_id === leadId)).filter(Boolean);
    };
    const dailSelected=()=>{
        if(selectedLeads.length > 0){
            const newQueue=findLeadsByIds(queue,selectedLeads);
            dispatch(setQueue(newQueue));
            setRefreshQueue(newQueue[0])
            jumpIntoQueue();
            setSelectedLeads([]);
        }
        else{

        }
    }
    return (
        <div className="bulkActionFooterWrapper" style={{marginTop: '-10px'}}>
            {selectedLeads.length > 0 && (
                <div className="selectionCount">Selected: {selectedLeads.length}</div>
            )}
            {buttons.map((button, buttonIndex) => (
                <div className="bulkActionFooterButton" key={buttonIndex}>
                    {size === 'small' ? (
                        <div className="bulkActionFooterIcon" onClick={() => button.short === 'Dial' ? dailSelected() : handleIconClick(buttonIndex)}>
                            {button.short}{button.label}

                            {button.short !== 'Dial' && (
                                <div className={`bulkSelectionHolder ${applyClassArray===buttonIndex ? 'showList' : ''}`}>
                                    <div className="bulkSelectionContent">
                                        <div className="quilityCloseWindow" onClick={(e)=>{closeModal();e.stopPropagation()}}>X</div>
                                        <div className="subTitle">{button.short}</div>
                                        {button.values[0].map((value, choiceIndex) => (
                                            <div className="quilityDataLine" key={choiceIndex} style={{lineHeight: 'normal', textAlign: 'left', textIndent: '10px'}}>
                                                <div className="quilityDataLineTitle" style={{width: '60%'}}>{value.name}</div>
                                                <div className="quilityDataLineValue" style={{display:'inline-block',fontWeight:'normal', cursor:'pointer'}} onClick={()=>bulk(value.id, button.search)}>Select</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <>
                            <div className="bulkActionFooterIcon">{button.icon}</div>
                            <div className="bulkActionFooterSmallLabel">{button.short}
                                <div className="bulkActionFooterBigLabel">{button.label}</div>
                            </div>
                        </>
                    )}
                </div>
            ))}
            {errorMessage && (
                <Warning title="Unable To Send Texts" message="You do not have any texting capable numbers registered to message from" buttons={[{name:"Okay", action:"confirm"}]} confirm={closeAlert}/>
            )}
            {warning && (
                <Warning title="Large Amount Of Leads" message={`Are you sure you want to send messages to this many leads? Doing this will text ${warning.length} people.`} buttons={[{name:"Cancel", action:"cancel"},{name:"I'm Sure", action:"confirm"}]} confirm={sendBulk} cancel={closeAlert}/>
            )}
            <SuccessAlert message="Your bulk actions have been applied" successTrigger={successTrigger} setSuccessTrigger={setSuccessTrigger} />
        </div>
    );
}

export default BulkActionFooter;

