import React, {useState, useRef, useEffect} from 'react';
import '../../css/conversation.css';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { useSelector } from 'react-redux';
import MessageBlock from './message';
import SendBar from './sendBar';
import { fileServer } from '../../config/commUrls';
import Icon from '../../assets/icons/icon';

function ActualConversation({setLoading,lead,conversation,travelPathway,handleSendText,convoId,handleInputChange,inputValue}) {
    const agentInfo=useSelector((state) => state.agent.userData); 
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [attachedImage, setAttachedImage] = useState(false);
    const sendBarRef = useRef();
    const [textBoxHeight, setTextBoxHeight] = useState(0);

    const handleRemoveImage=()=>{
        setAttachedImage(false);
    }
    const handleEmojiSelect = (emoji) => {
        handleInputChange(inputValue+emoji.native);
    };
    const handleEmojiPicker=()=>{
        setShowEmojiPicker(!showEmojiPicker);
    }
    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
     
        const formData = new FormData();

        formData.append('file', file);

        try {
            const backend_port = process.env.REACT_APP_BACKEND_PORT ? process.env.REACT_APP_BACKEND_PORT : 3333
            const response = await fetch(`https://${window.location.hostname}:${backend_port}/`, {
                method: 'POST',
                body: formData,
            });
            const jsonData = await response.json();
            setAttachedImage(`${fileServer}/mmsImages/${jsonData[0].file}`)
        } catch (error) {
            console.error('Error:', error.message);
        }
    };
    const sendSuggestion=async(template)=>{
        let modifiedContent = Object.entries(lead[0]).reduce((content, [key, value]) => {
            const replacement = value !== null ? value : '';
            return content.replace(new RegExp(`{${key.replace('borrower','lead')}}`, 'g'), `${replacement}`);
        }, template);
        if(modifiedContent.includes('{agent_first}')){
            modifiedContent = modifiedContent.replace(/{agent_first}/g, agentInfo.firstName);
        }
        if(modifiedContent.includes('{agent_last}')){
            modifiedContent = modifiedContent.replace(/{agent_last}/g, agentInfo.lastName);
        }
        travelPathway(lead[0].lead_id, lead[0].pathway);
        handleChange(modifiedContent);
        lead[0].pathway=null;
    }
    // console.log("lead==$$",lead[0])

    // Function to handle input change
    const handleChange = (value) => {
        handleInputChange(value);
    };
  
    useEffect(() => {
        if(lead){
            if(lead[0].pathway){
                setTextBoxHeight(243); 
            }
            else{
                setTextBoxHeight(193); 
            }
            
        }
        else{
            setTextBoxHeight(103); 
        }
        handleInputChange('')
    }, [lead && lead[0].lead_id]);
    return (
        <div className="outerConvo">
            <div className="convoInnerWrapper" style={{height: lead.length > 0 && lead[0].pathway ? `calc(100% - ${textBoxHeight}px)`:`calc(100% - ${textBoxHeight}px)`}}>
                {conversation && conversation.map((message, index) => (
                    <MessageBlock message={message} index={index} key={index}/>
                ))}
            </div>
            
            <div className="sendWrapper" ref={sendBarRef}>
                {lead.length > 0 && lead[0].pathway && (
                    <div className="pathSuggestion">
                        <div className="suggestionHolder">
                            <div className="suggestion" onClick={()=>sendSuggestion(lead[0].convoFlowContent)}><span className="fontello" style={{marginTop: '-7px', marginRight:'10px'}}><Icon name={'q-lightning-bolt-fast'} size={24} className="suggestionIcon" adjust="fontello" /></span>{lead[0].convoFlowName}</div>
                        </div>
                    </div>
                )}
                <div className="sendingImageWrapper">
                    {attachedImage && (
                        <div className="innerImageWrapper">
                            <div className="removeImage" onClick={()=>handleRemoveImage()}>x</div>
                            <img src={attachedImage} alt="Preview" className="sendingImage"/>
                        </div>
                    )}
                </div>
                <SendBar handleFileUpload={handleFileUpload} handleEmojiPicker={handleEmojiPicker} attachedImage={attachedImage} setTextBoxHeight={setTextBoxHeight} handleChange={handleChange} handleInputChange={handleInputChange} inputValue={inputValue} handleSendText={handleSendText} convoId={convoId} setLoading={setLoading} setAttachedImage={setAttachedImage} sendBarRef={sendBarRef} lead={lead}/>
            </div>
            {showEmojiPicker && (
                <div className="emojiWrapper" style={{ position: 'absolute',bottom: '100px'}} >
                    <Picker
                        data={data} onEmojiSelect={handleEmojiSelect}
                    />
                </div>
            )}
        </div>
    );
}

export default ActualConversation;
