import React, {useState} from 'react';
import '../../css/login.css';
import image from '../../assets/icons/QuilitySwitchboardFunnel.svg';
import { useDispatch } from 'react-redux';
import { setPopups } from '../../features/popupSlice';
import { fetchWithDefaults } from '../../config/fetchDefault';
import PopupItem from './popupItem';
import Icon from '../../assets/icons/icon';
import { useSelector } from 'react-redux';

  
function InformationPopup({popups,agentCode}) {
    const dispatch = useDispatch();
    const [data, setData] = useState('America/New_York');
    const agentInfo=useSelector((state) => state.agent.userData);
    const saveInfo=async (value)=>{
        const updatedPopups = popups.filter(item => item !== item);
        dispatch(setPopups(updatedPopups));
        try {
            const requestOptions = {
                method: 'PATCH',
                body: JSON.stringify({ 
                    column: 'timezone',
                    value: value,
                    agent:agentInfo.agentId
                }) 
            };
            const response = await fetchWithDefaults('/agents/update/agent', requestOptions);
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const jsonData = await response.json();
            if(jsonData[0].status===true){

            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    const closeWindow=()=>{
        dispatch(setPopups([]));
    }
    const sso=()=>{
        window.location.href=`${process.env.REACT_APP_FUNNEL_AUTH_QUILITY}authorize?response_type=code&client_id=kCMuOYXm4BSuAII1sJPoEeRjTgH9Pr1J&redirect_uri=https://${window.location.hostname}/api/quility/authorize&scope=profile email read:Agents&audience=http://api.quility.com&state=link_${agentInfo.agentId}`
    }
    return (
        <div className="signInWrapper">
            <div className="signInContent">
            
                <div className="loginWrapper">
                    <div className="quilityCloseWindow" onClick={() => closeWindow()} style={{top:'5px'}}><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'x-circle-q'} size={34} className="closeIcon" adjust="fontello" /></span></div>
                    <div className="logo"><img src={image} className="imageStyle" alt="Logo" /></div>
                    <div className="smallTitle">Sorry for the interruption, we have a quick question</div>
                    {popups ? 
                        popups.map((popup, index) => (
                            <PopupItem popup={popup} setData={setData} data={data}/>
                        )
                    ) : agentCode ? (
                        "We are missing your Agent Code from Symmetry"
                    ) : (
                        null
                    )}
                    <div className="buttonWrapper" style={{marginTop: '20px'}}>
                        {agentCode ? (
                            <button type="submit" className="loginButton" name="submitSignin" onClick={() => sso()}>Sync Accounts</button>
                        ) : (
                            <button type="submit" className="loginButton" name="submitSignin" onClick={() => saveInfo(data)}>Save</button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InformationPopup;