import React, {useState} from 'react';
import '../../css/market.css';
import MarketSection from './marketSection';
import CurrentPageHandler from './currentPageHandler';
import PastOrders from './pastOrders';


function Market({ data}) {
    const [sections, setSection]=useState(data[0].sections);
    const [leadPreview, setLeadPreview]=useState(data[0].results[0].leads[0]);
    const [workflowPreview, setWorkflowPreview]=useState(data[0].results[0].workflows[0]);

    const [currentPage, setCurrentPage]=useState(null);
    const [pathData, setPathData]=useState(null);

    const handleNavigation = (name,icon) => {
        if(name === 'Lighthouse Leads'){
            window.open(`${process.env.REACT_APP_FUNNEL_HQ_QUILITY}leads/lighthouse-lead-orders`,'_blank')
        } else {
            setCurrentPage(name);
        }
    };

    return (
        <div className="outerWrapper">
           <span className="outerWrapperTitle"><span className="outerWrapperIcon"></span>Market</span>
            <div className="mainBlockOuterWrapper">
            {currentPage ? (
                <CurrentPageHandler pathData={pathData} handleNavigation={handleNavigation} setPathData={setPathData} page={currentPage} setCurrentPage={setCurrentPage}/>
            ) : (
                <>
                    <PastOrders/>
                    {sections ? (
                        sections.map((section, index) => (
                            <MarketSection key={`markets${index}`} handleNavigation={handleNavigation} title={section.name} workflowPreview={workflowPreview} leadPreview={leadPreview}/>
                        ))
                    ) : (
                        null
                    )}
                </>
            )}

        </div>
    </div>

    );
}

export default Market;
