import React, { useState, useRef, useEffect } from 'react';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { fetchWithDefaults } from '../../../config/fetchDefault';
import { fetchPdf } from '../../../utilities/messagingFunctions';
import { gatherConversation, travelPathway } from '../../../utilities/conversationFunctions';
import { formatPhoneNumber } from '../../../utilities/dashFunction';
import TextInputBar from './textBar';
import { useDispatch, useSelector } from 'react-redux';
import { fileServer, prodBackend } from '../../../config/commUrls';
import Icon from '../../../assets/icons/icon';
import { addSocketListener, removeSocketListener, getSocket } from '../../../sockets';
import Tooltip from '../../../utilities/helperComponents/tooltip';
import { refreshNumbers } from '../../../utilities/conversationFunctions';
import { getConversations } from '../../../utilities/appSetup';
import { unixTommddhia } from '../../../utilities/dashFunction'

function QueueConvo({ handleLeadData, expiration, setLoading, currentLead, from, textingNumbers, conversations, setConversations, currentLeadId, lead, canMessageTCPA, setCanMessageTCPA }) {
    const isFeature875Enabled = process.env.REACT_APP_FUNNEL_FEATURE_875 === 'true'
    const isFeature1042Enabled = process.env.REACT_APP_FUNNEL_FEATURE_1042 === 'true'
    const agentInfo = useSelector((state) => state.agent.userData);
    const agentId = useSelector((state) => state.agent.userData).agentId;
    const [addConvo, setAddConvo] = useState(false);
    const texts = useSelector((state) => state.texts.texts);
    const [textsTemplates, setTextsTemplates] = useState(false);
    const [suggestionUse, setSuggestionUse] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [fromNumber, setFromNumber] = useState(false);
    const [theirNumbers, setTheirNumbers] = useState(false);
    const [newConvo, setNewConvo] = useState([]);
    const [toNumber, setToNumber] = useState(false);
    const [currentConvoId, setCurrentConvoId] = useState(false);
    const [convoDisplay, setConvoDisplay] = useState(1);
    const [cursorPosition, setCursorPosition] = useState(0);
    const [attachedImage, setAttachedImage] = useState(false);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [textTemplates, setTextTemplates] = useState(false);
    const [variables, setVariables] = useState(false);
    const fileInputRef = useRef(null);
    const [textBoxHeight, setTextBoxHeight] = useState(from === 'leadCard' ? 90 : 250);
    const keysWithValue = [];
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredTexts, setFilteredTexts] = useState([]);
    const [num, setNum] = useState([]);
    const dispatch = useDispatch()

    const varsToUse = ['pdf', 'borrower_first', 'borrower_last', 'email', 'address', 'city', 'state', 'zip', 'mortgage', 'borrower_occupation', 'hobby', 'lender', 'loan_type', 'motivation', 'payment', 'policy', 'policy_number', 'policy_plan', 'tobacco', 'height', 'weight', 'borrower_age', 'borrower_dob', 'borrower_sex', 'co_borrower_first', 'co_borrower_last', 'co_borrower_age', 'co_borrower_dob', 'co_borrower_email', 'co_borrower_occupation'];
    const openvars = () => {
        if (canMessageTCPA === false && isFeature875Enabled === true) {
            return
        }

        setVariables(!variables);
    }
    const keysandvalues = (leadInfo) => {

        if (lead.custom_fields !== '') {
            const custom = JSON.parse(lead.custom_fields);
            for (const key in custom) {
                if (custom[key] !== null && custom[key] !== undefined) {
                    const newkey = key.replace('borrower', 'Lead');
                    keysWithValue.push({ key: newkey, value: custom[key] });
                }
            }
            for (const key in leadInfo) {
                if (leadInfo[key] !== null && leadInfo[key] !== undefined && varsToUse.includes(key)) {
                    const newkey = key.replace('borrower', 'Lead');
                    keysWithValue.push({ key: newkey, value: leadInfo[key] });
                }
            }
        }
        else {
            for (const key in leadInfo) {
                if (leadInfo[key] !== null && leadInfo[key] !== undefined && varsToUse.includes(key)) {
                    const newkey = key.replace('borrower', 'Lead');
                    keysWithValue.push({ key: newkey, value: leadInfo[key] });
                }
            }
        }

    }
    keysandvalues(lead);
    const handleFileUpload = async (e) => {
        if (canMessageTCPA === false && isFeature875Enabled === true) {
            return
        }

        const file = e.target.files[0];

        const formData = new FormData();

        formData.append('file', file);

        try {
            const response = await fetch(`${prodBackend}/sendImage`, {
                method: 'POST',
                body: formData,
            });
            const jsonData = await response.json();
            setAttachedImage(`${fileServer}/mmsImages/${jsonData[0].file}`)
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    const handleImageClick = () => {
        if (canMessageTCPA === false && isFeature875Enabled === true) {
            return
        }

        fileInputRef.current.click();
    };
    const handleChange = (input) => {
        setInputValue(input);
    };

    const filterEmptyValues = (element1, element2, element3) => {
        const nonEmptyValues = [];
        let setMe = "";

        if (element1 !== null && element1 !== undefined && element1 !== '') {
            nonEmptyValues.push(element1);
            setMe = element1;
        }

        if (element2 !== null && element2 !== undefined && element2 !== '') {
            nonEmptyValues.push(element2);
            if (setMe != '') {
                setMe = element2;
            }
        }

        if (element3 !== null && element3 !== undefined && element3 !== '') {
            nonEmptyValues.push(element3);
            if (setMe != '') {
                setMe = element3;
            }
        }
        return nonEmptyValues;
    };

    const [unreadMsgCount, handleSetUnread] = useState('');

    const sendText = async (key) => {
        if (canMessageTCPA === false && isFeature875Enabled === true) {
            return
        }

        const textToSend = inputValue.replace(/<br>/g, '\n');
        if (textToSend !== '' || attachedImage) {
            if (suggestionUse) {
                await travelPathway(currentLeadId, lead.pathway, handleLeadData);
            }
            let toNew = toNumber;
            let fromNew = fromNumber;
            if (typeof fromNew === 'object') {
                fromNew = fromNumber.number;
            }
            if ((!toNew || !fromNew) && currentConvoId) {
                toNew = atob(currentConvoId).split(':')[1];
                fromNew = atob(currentConvoId).split(':')[0];
            }
            if (typeof toNew !== 'string') {
                toNew = '+1' + toNew;
            }
            if (fromNew && fromNew !== '') {

                try {

                    const requestOptions = {
                        method: 'POST',
                        body: JSON.stringify({
                            agent: agentInfo.agentId,
                            to: toNew,
                            from: fromNew,
                            body: textToSend,
                            team: agentInfo.teamId,
                            lead: currentLeadId,
                            image: attachedImage
                        })
                    };

                    const response = await fetchWithDefaults('/sendText', requestOptions);
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const jsonData = await response.json();
                    if (jsonData.status === true) {
                        const timestamp = new Date().toLocaleString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true }).replace(',', '');
                        const newNotes = `Text Sent - ${timestamp} <div className="previewTextWrapper"><div className="previewTextTitle">View Text</div><div className="previewText">${textToSend}</div></div><br> ${lead.notes}`;
                        await gatherConversation(currentLead.lead_id, setConversations, dispatch)
                        // Store the latest conversation in redux,
                        getConversations(handleSetUnread, agentInfo?.agentId, dispatch);
                        setInputValue('');
                        setAttachedImage(false)
                        handleLeadData('notes', newNotes);
                        setConvoDisplay(1);
                        setAddConvo(false);

                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        }
        else {

        }
    }


    const handleHeight = (height) => {
        if (from === 'leadCard') {
            if (height !== 50) {
                setTextBoxHeight(90 + height);
            }
            else {
                setTextBoxHeight(140);
            }
        }
        else {
            if (height !== 50) {
                setTextBoxHeight(160 + height);
            }
            else {
                setTextBoxHeight(210);
            }
        }
    };
    useEffect(() => {
        if (currentConvoId) {
            const socket = getSocket();
            socket.on(`${currentConvoId}`, (message) => {
                const newMessage = {
                    direction: "to",
                    image: "",
                    message: message.message,
                    time: message.time
                }

                const foundIndex = conversations.findIndex(obj => {
                    const key = Object.keys(obj)[0]; // Get the first key of the object
                    return key === currentConvoId;   // Compare the key with currentConvoId
                });

                if (foundIndex !== -1) {
                    // Create a new conversations array by copying the old one
                    const updatedConversations = [...conversations];
                    // Update the found conversation's messages array immutably
                    updatedConversations[foundIndex] = {
                        ...updatedConversations[foundIndex],
                        [currentConvoId]: [
                            newMessage,
                            ...updatedConversations[foundIndex][currentConvoId]
                        ],
                    };

                    // Update the state with the new conversations array
                    setConversations(updatedConversations);
                }
            })
        }
        return () => {
            removeSocketListener(`${currentConvoId}`);
        };
    }, [currentConvoId, conversations]);

    useEffect(() => {
        refreshNumbers(agentId, setNum)
    }, []);

    function formatConvoId(inputString) {
        //setConvoId(inputString);

        const convoString = atob(inputString);
        // Split the input string based on the colon
        const numbersArray = convoString.split(':');

        // Format each number in the array
        const formattedNumbers = numbersArray.map((number) => {
            const cleanedNumber = number.replace(/\D/g, ''); // Remove non-numeric characters
            const formatted = `(${cleanedNumber.slice(1, 4)}) ${cleanedNumber.slice(4, 7)}-${cleanedNumber.slice(7)}`;
            return formatted;
        });

        // Join the formatted numbers with ' & ' between them
        const result = formattedNumbers.join(' & ');
        //addSocketListener()

        return result;
    }
    const handleCursorPosition = (pos) => {
        setCursorPosition(pos);
    }
    const handleClick = (value, key) => {
        if (canMessageTCPA === false && isFeature875Enabled === true) {
            return
        }

        if (key === 'pdf') {
            fetchPdf(value, setAttachedImage, setVariables, setLoading);
        }
        else {
            const updatedValue = inputValue.slice(0, cursorPosition) + value + inputValue.slice(cursorPosition);
            handleInputChange(updatedValue);
            setCursorPosition(cursorPosition + value.length);
            setVariables(!variables);
        }
    };


    useEffect(() => {
        setFilteredTexts(texts)
        const numbers = filterEmptyValues(currentLead.borrower_cell, currentLead.borrower_home, currentLead.borrower_work);
        setTheirNumbers(numbers)
        setToNumber(numbers[0]);
        setFromNumber(textingNumbers[0]);
        setNewConvo([]);
        setConvoDisplay(1);
        if (conversations.length > 0) {
            Object.keys(conversations[0]).map((key) => (
                setCurrentConvoId(key)

            ))
        }

    }, [currentLead, conversations]);
    useEffect(() => {
        gatherConversation(currentLead.lead_id, setConversations, dispatch)
    }, [currentLead]);

    const handleRemoveImage = () => {
        setAttachedImage(false);
    }
    const handleToChange = (event) => {
        // Update the state with the selected value
        setToNumber(event.target.value);
    };
    const handleFromChange = (event) => {
        // Update the state with the selected value
        setFromNumber(event.target.value);
    };
    const showConvo = (index, convo) => {
        setConvoDisplay(index);
        setCurrentConvoId(null);
        setFromNumber(num[0]?.phoneNumber);
    }

    const toggleConvo = (index, convo) => {
        setConvoDisplay(index);
        let theNewTo;
        let theNewFrom;
        Object.keys(convo).map((key) => (
            theNewTo = atob(key).split(':')[1],
            theNewFrom = atob(key).split(':')[0],
            setCurrentConvoId(key)
        ))
        setFromNumber(theNewFrom);
        setToNumber(theNewTo);
    }

    const handleEmojiPicker = () => {
        if (canMessageTCPA === false && isFeature875Enabled === true) {
            return
        }

        setShowEmojiPicker(!showEmojiPicker);
    }
    const handleEmojiSelect = (emoji) => {
        if (canMessageTCPA === false && isFeature875Enabled === true) {
            return
        }

        setInputValue(inputValue + emoji.native);
    };
    const handleTextTemplates = () => {
        if (canMessageTCPA === false && isFeature875Enabled === true) {
            return
        }

        setTextTemplates(!textTemplates);
    }
    const handleInputChange = (content) => {
        if (canMessageTCPA === false && isFeature875Enabled === true) {
            return
        }

        setInputValue(content);
    };
    const addTemplate = (template, images) => {
        if (canMessageTCPA === false && isFeature875Enabled === true) {
            return
        }

        openTemplates();

        if (images) {
            if (!images.includes('http')) {
                const newImageLink = `${fileServer}/${images}`;
                setAttachedImage(newImageLink);
            } else {
                setAttachedImage(images);
            }
        }

        // Replace null or undefined values in currentLead with ''
        let modifiedContent = Object.entries(currentLead || {}).reduce((content, [key, value]) => {
            const replacement = value != null ? value : ''; // Use != to check for both null and undefined
            return content.replace(new RegExp(`{${key.replace('borrower', 'lead')}}`, 'g'), `${replacement}`);
        }, template || '');

        modifiedContent = modifiedContent.replace(/\\n/g, '<br>');

        if (modifiedContent.includes('{agent_first}')) {
            modifiedContent = modifiedContent.replace(/{agent_first}/g, agentInfo?.firstName || '');
        }
        if (modifiedContent.includes('{agent_last}')) {
            modifiedContent = modifiedContent.replace(/{agent_last}/g, agentInfo?.lastName || '');
        }
        if (modifiedContent.includes('{lead_pdf}')) {
            modifiedContent = modifiedContent.replace(/{lead_pdf}/g, '');
            if (lead?.pdf) {
                fetchPdf(lead.pdf, setAttachedImage, setVariables, setLoading);
            }
        }

        // Replace null or undefined values in custom fields with ''
        const custom = JSON.parse(lead.custom_fields || '{}');
        let modifiedContent2 = Object.entries(custom).reduce((content, [key, value]) => {
            const replacement = value != null ? value : ''; // Use != to check for both null and undefined
            return content.replace(new RegExp(`{${key.replace('borrower', 'lead')}}`, 'g'), `${replacement}`);
        }, modifiedContent);

        handleTextTemplates();
        handleInputChange(inputValue + modifiedContent2);
    }

    const openTemplates = () => {
        if (canMessageTCPA === false && isFeature875Enabled === true) {
            return
        }

        setTextsTemplates(!textsTemplates);
    }
    const filterTexts = (searchTerm) => {
        const filtered = texts.filter(text => text.name.toLowerCase().includes(searchTerm.toLowerCase()));
        setFilteredTexts(filtered);
    };
    const toggleNewConvo = () => {
        setAddConvo(true);
    }
    const sendSuggestion = async (template) => {
        let modifiedContent = Object.entries(lead).reduce((content, [key, value]) => {
            const replacement = value !== null ? value : '';
            return content.replace(new RegExp(`{${key.replace('borrower', 'lead')}}`, 'g'), `${replacement}`);
        }, template);
        if (modifiedContent.includes('{agent_first}')) {
            modifiedContent = modifiedContent.replace(/{agent_first}/g, agentInfo.firstName);
        }
        if (modifiedContent.includes('{agent_last}')) {
            modifiedContent = modifiedContent.replace(/{agent_last}/g, agentInfo.lastName);
        }
        setSuggestionUse(true);
        //travelPathway(lead[0].lead_id, lead[0].pathway);
        handleChange(modifiedContent);
        handleHeight();
        //lead.pathway=null;
    }

    const textInpurBarProps = {
        handleHeight,
        handleCursorPosition,
        inputValue,
        handleChange,
        canEditContent: canMessageTCPA,
    }

    const sendTCPAIntroText = async () => {
        if (canMessageTCPA === true) {
            return
        }


        const introText = `Hello, this is ${agentInfo.firstName} ${agentInfo.lastName} following up with your request for ${currentLead.lead_type} quotes. Text STOP to stop sales texts.`
        console.log(introText)

        let toNew = toNumber;
        let fromNew = fromNumber;
        if (typeof fromNew === 'object') {
            fromNew = fromNumber.number;
        }
        if (currentConvoId) {
            toNew = atob(currentConvoId).split(':')[1];
            fromNew = atob(currentConvoId).split(':')[0];
        }
        if (typeof toNew !== 'string') {
            toNew = '+1' + toNew;
        }

        if (fromNew && fromNew !== '') {
            try {
                const requestOptions = {
                    method: 'POST',
                    body: JSON.stringify({
                        agent: agentInfo.agentId,
                        to: toNew,
                        from: fromNew,
                        body: introText,
                        team: agentInfo.teamId,
                        lead: currentLeadId
                    })
                };

                const response = await fetchWithDefaults('/sendText', requestOptions);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const jsonData = await response.json();
                if (jsonData.status === true) {
                    const theConvo = btoa(fromNew + ':' + toNew);
                    const timestamp = new Date().toLocaleString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true }).replace(',', '');
                    const newNotes = `Text Sent - ${timestamp} <div className="previewTextWrapper"><div className="previewTextTitle">View Text</div><div className="previewText">${introText}</div></div><br> ${lead.notes}`;

                    const newCovo = [{ [theConvo]: [jsonData] }];
                    setConversations(newCovo)
                    setInputValue('');
                    setAttachedImage(false)
                    handleLeadData('notes', newNotes);
                    setCanMessageTCPA(true)
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    }

    const [showTCPAToolTipTextBox, setShowTCPAToolTipTextBox] = useState(false)
    const [queueConvoWrapperHeight, setQueueConvoWrapperHeight] = useState(null)

    useEffect(() => {
        if (isFeature1042Enabled === true && ((conversations.length < 1) || (isFeature875Enabled === true && conversations.length <= 1 && canMessageTCPA === false))) {
            setQueueConvoWrapperHeight(null)
        } else if (from === 'leadCard') {
            setQueueConvoWrapperHeight('calc(100% - 40px)')
        } else if (conversations.length > 1) {
            setQueueConvoWrapperHeight('calc(100% - 140px)')
        } else {
            setQueueConvoWrapperHeight('calc(100% - 50px)')
        }
    }, [conversations, canMessageTCPA, from])

    const [msgTimestampTracker, setMsgTimestampTracker] = useState({})
    const updateMsgTimestampTracker = (msgTs, convoKey) => {
        const key = `${convoKey}-${msgTs}`
        let updatedTracker = {...msgTimestampTracker, ...{[key]: !!!msgTimestampTracker[key]}}

        setMsgTimestampTracker(updatedTracker)
    }

    return (

        <div className="queueConvoWrapper" style={{ height: queueConvoWrapperHeight, width: `${from === 'leadCard' ? '100%' : '90%'}` }}>

            <div className="queueConvoHeader">
                {conversations?.length < 1 && (
                    <div className="queueConversationButton">New Conversation</div>
                )}
                {conversations && (
                    <>
                        {conversations.map((convo, index) => (
                            <div key={`conversationBox${index}`} className={`queueConversationButton ${convoDisplay === (index + 1) ? 'selected' : ''}`} onClick={() => toggleConvo(index + 1, convo)}>Convo #{index + 1}</div>
                        ))}
                    </>
                )}
            </div>
            {(conversations.length < 1 && ((isFeature875Enabled === false) || (isFeature875Enabled === true && canMessageTCPA === true))) ? (
                <div className="queueConversationWrapper" style={{ height: lead.pathway ? 'calc(100% - 50px)' : '100%' }}>
                    <div className="conversationTitle">
                        <span className="fromNumWrapper">
                            <select className="numberSelectBox" value={fromNumber} onChange={handleFromChange}>
                                {num && (
                                    num.map((number, index) => (
                                        <option key={`num${index}`} value={number.number}>{number.friendlyName}</option>
                                    ))
                                )}
                            </select>
                        </span>
                        <span style={{ padding: '0px 5px' }}>&</span>
                        <span className="toNumWrapper">
                            <select className="numberSelectBox" value={toNumber} onChange={handleToChange}>
                                {theirNumbers && (
                                    theirNumbers.map((number, index) => (
                                        <option key={`theirNumber${index}`}>{formatPhoneNumber(number.toString())}</option>
                                    ))
                                )}
                            </select>
                        </span>
                    </div>
                    <div className="queueConversationInnerWrapper" style={{ height: `calc(100% - ${textBoxHeight}px` }}>
                    {newConvo && newConvo.map((message, index) => (
                        <div className={`messageWrapper message${message.direction}`} key={`newConvo${index}`}>
                            {!!msgTimestampTracker[`NEW-${message.time}`] && (
                                <div style={{margin: '0 5px'}}>{unixTommddhia(message.time, agentInfo.timezone)}</div>
                            )}
                            <div onDoubleClick={ _ => updateMsgTimestampTracker(message.time, 'NEW')} className={`convoBubble background${message.direction}`}>
                                {message.image && message.image != 0 && (
                                    <div className="sentImageWrapper">
                                        <img src={message.image} alt="Preview" className="sendingImage"/>
                                    </div>
                                )}
                               {message.message.split('\n').map((line, i) => (
                                    <React.Fragment key={i}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    ))}
                    </div>
                    {lead.pathway && (
                        <div className="pathSuggestion">
                            <div className="suggestionHolder">
                                <div className="suggestion" onClick={() => sendSuggestion(lead.convoFlowContent)}><span className="fontello" style={{ marginTop: '-7px', marginRight: '10px' }}><Icon name={'q-lightning-bolt-fast'} size={24} className="suggestionIcon" adjust="fontello" /></span>{lead.convoFlowName}</div>
                            </div>
                        </div>
                    )}
                    <div className="sendingImageWrapper">
                        {attachedImage && (
                            <div className="innerImageWrapper">
                                <div className="removeImage" onClick={() => handleRemoveImage()}>x</div>
                                <img src={attachedImage} alt="Preview" className="sendingImage" />
                            </div>
                        )}
                    </div>
                    {parseInt(expiration) > 0 || !expiration ? (
                        <div className="sendBar">
                            <div className="inputBarWrapper">
                                <TextInputBar {...textInpurBarProps} />
                            </div>
                            <div className={`sendButton ${(canMessageTCPA === false && isFeature875Enabled === true) ? 'disabled' : ''}`} onClick={() => sendText()}><span className="fontello" style={{ marginTop: '-7px' }}><Icon name={'send-q'} height='38' width='30' className="" adjust="fontello" /></span></div>
                            <div className="sendBarOptions">
                                <div className={`optionButton ${(canMessageTCPA === false && isFeature875Enabled === true) ? 'disabled' : ''}`}>
                                    <span style={{ fontSize: '30px' }} onClick={() => handleEmojiPicker()}>&#9786;</span>
                                </div>
                                <div className={`optionButton ${(canMessageTCPA === false && isFeature875Enabled === true) ? 'disabled' : ''}`} onClick={handleImageClick}>
                                    <span className="fontello" style={{ marginTop: '-7px' }}><Icon name={'image-q'} height='38' width='30' className="" adjust="fontello" /></span>
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        onChange={handleFileUpload}
                                        disabled={canMessageTCPA === false && isFeature875Enabled === true}
                                    />
                                </div>
                                <div className={`optionButton ${(canMessageTCPA === false && isFeature875Enabled === true) ? 'disabled' : ''}`}>
                                    <span className="fontello" style={{ marginTop: '-7px' }}><Icon name={'clock-q'} height='38' width='30' className="" adjust="fontello" /></span>
                                </div>
                                <div className={`optionButton ${(canMessageTCPA === false && isFeature875Enabled === true) ? 'disabled' : ''}`} onClick={() => openvars()}>
                                    <span className="fontello" style={{ marginTop: '-7px' }}><Icon name={'q-brackets-plus'} height='38' width='30' className="" adjust="fontello" /></span>
                                </div>
                                {variables && (
                                    <div className="variableWindow">
                                        <div className="innerWrapper">
                                            {keysWithValue.map((variable, index) => (
                                                <div className="leadInfoLine" key={`variable${index}`}>
                                                    <span className="dataFieldTitle">{variable.key.replace('_', ' ')}</span>
                                                    <span className="leadData" onClick={() => handleClick(variable.value, variable.key)} style={{ cursor: 'pointer' }}>Click To Use</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                <div className={`optionButton ${(canMessageTCPA === false && isFeature875Enabled === true) ? 'disabled' : ''}`} onClick={() => openTemplates()}>
                                    <span className="fontello" style={{ marginTop: '-7px' }}><Icon name={'file-text-q'} height='38' width='30' className="" adjust="fontello" /></span>
                                </div>
                                {textsTemplates && (
                                    <div className="variableWindow">
                                        <input
                                            type="text"
                                            placeholder="Search templates..."
                                            className="templateSearch"
                                            value={searchTerm}
                                            onChange={(e) => {
                                                setSearchTerm(e.target.value);
                                                filterTexts(e.target.value);
                                            }}
                                            disabled={canMessageTCPA === false && isFeature875Enabled === true}
                                        />
                                        <div className="innerWrapper">
                                            {filteredTexts.map((text, index) => (
                                                <div className="leadInfoLine" key={`texttemplate${index}`}>
                                                    <span className="dataFieldTitle">{text.name}</span>
                                                    <span className="leadData" onClick={() => addTemplate(text.content, text.images)} style={{ cursor: 'pointer' }}>Click To Use</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : expiration > -1209600 ? (
                        <div className="expiredText">You can no longer message this lead. You have {Math.round((expiration + 1209600) / 86400)} days left before texting is permanently disabled</div>
                    ) : (
                        <div className="expiredText">You can no longer message this lead</div>
                    )}
                    {showEmojiPicker && (
                        <div className="emojiWrapper" style={{
                            position: 'absolute', bottom: '50px'
                        }} >
                            <Picker
                                data={data} onEmojiSelect={handleEmojiSelect}
                            />
                        </div>
                    )}

                </div>
            ) : addConvo === true && (

                <div className="queueConversationWrapper" style={{ height: lead.pathway ? 'calc(100% - 50px)' : '100%' }}>
                    <div className="conversationTitle">
                        <span className="fromNumWrapper">
                            <select className="numberSelectBox" value={fromNumber} onChange={handleFromChange}>
                                {num && (
                                    num.map((number, index) => (
                                        <option key={`num${index}`} value={number.phoneNumber}>{number.friendlyName}</option>
                                    ))
                                )}
                            </select>
                        </span>
                        &
                        <span className="toNumWrapper">
                            <select className="numberSelectBox" value={toNumber} onChange={handleToChange}>
                                {theirNumbers && (
                                    theirNumbers.map((number, index) => (
                                        <option key={`theirNumber${index}`}>{formatPhoneNumber(number.toString())}</option>
                                    ))
                                )}
                            </select>
                        </span>
                    </div>
                    <div className="queueConversationInnerWrapper" style={{ height: `calc(100% - ${textBoxHeight}px` }}>
                    {newConvo && newConvo.map((message, index) => (
                        <div className={`messageWrapper message${message.direction}`} key={`newConvo${index}`}>
                            {!!msgTimestampTracker[`NEW-${message.time}`] && (
                                <div style={{margin: '0 5px'}}>{unixTommddhia(message.time, agentInfo.timezone)}</div>
                            )}
                            <div onDoubleClick={ _ => updateMsgTimestampTracker(message.time, 'NEW')} className={`convoBubble background${message.direction}`}>
                                {message.image && message.image != 0 && (
                                    <div className="sentImageWrapper">
                                        <img src={message.image} alt="Preview" className="sendingImage"/>
                                    </div>
                                )}
                                {message.message.split('\n').map((line, i) => (
                                    <React.Fragment key={i}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    ))}
                    </div>
                    {lead.pathway && (
                        <div className="pathSuggestion">
                            <div className="suggestionHolder">
                                <div className="suggestion" onClick={() => sendSuggestion(lead.convoFlowContent)}><span className="fontello" style={{ marginTop: '-7px', marginRight: '10px' }}><Icon name={'q-lightning-bolt-fast'} size={24} className="suggestionIcon" adjust="fontello" /></span>{lead.convoFlowName}</div>
                            </div>
                        </div>
                    )}
                    <div className="sendingImageWrapper">
                        {attachedImage && (
                            <div className="innerImageWrapper">
                                <div className="removeImage" onClick={() => handleRemoveImage()}>x</div>
                                <img src={attachedImage} alt="Preview" className="sendingImage" />
                            </div>
                        )}
                    </div>
                    <div className="sendBar">
                        <div className="inputBarWrapper">
                            <TextInputBar {...textInpurBarProps} />
                        </div>
                        <div className={`sendButton ${(canMessageTCPA === false && isFeature875Enabled === true) ? 'disabled' : ''}`} onClick={() => sendText()}><span className="fontello" style={{ marginTop: '-7px' }}><Icon name={'send-q'} height='38' width='30' className="" adjust="fontello" /></span></div>
                        <div className="sendBarOptions">
                            <div className={`optionButton ${(canMessageTCPA === false && isFeature875Enabled === true) ? 'disabled' : ''}`}><span style={{ fontSize: '30px' }} onClick={() => handleEmojiPicker()}>&#9786;</span></div>
                            <div className={`optionButton ${(canMessageTCPA === false && isFeature875Enabled === true) ? 'disabled' : ''}`} onClick={handleImageClick}><span className="fontello" style={{ marginTop: '-7px' }}><Icon name={'image-q'} height='38' width='30' className="" adjust="fontello" /></span>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleFileUpload}
                                    disabled={canMessageTCPA === false && isFeature875Enabled === true}
                                />
                            </div>
                            <div className={`optionButton ${(canMessageTCPA === false && isFeature875Enabled === true) ? 'disabled' : ''}`}><span className="fontello" style={{ marginTop: '-7px' }}><Icon name={'clock-q'} height='38' width='30' className="" adjust="fontello" /></span></div>
                            <div className={`optionButton ${(canMessageTCPA === false && isFeature875Enabled === true) ? 'disabled' : ''}`} onClick={() => openvars()}><span className="fontello" style={{ marginTop: '-7px' }}><Icon name={'q-brackets-plus'} height='38' width='30' className="" adjust="fontello" /></span></div>
                            {variables && (
                                <div className="variableWindow">
                                    <div className="innerWrapper">
                                        {keysWithValue.map((variable, index) => (
                                            <div className="leadInfoLine" key={`variable${index}`}><span className="dataFieldTitle">{variable.key.replace('_', ' ')}</span><span className="leadData" onClick={() => handleClick(variable.value, variable.key)} style={{ cursor: 'pointer' }}>Click To Use</span></div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            <div className={`optionButton ${(canMessageTCPA === false && isFeature875Enabled === true) ? 'disabled' : ''}`} onClick={() => openTemplates()}><span className="fontello" style={{ marginTop: '-7px' }}><Icon name={'file-text-q'} height='38' width='30' className="" adjust="fontello" /></span></div>
                            {textsTemplates && (
                                <div className="variableWindow">
                                    <input
                                        type="text"
                                        placeholder="Search templates..."
                                        className="templateSearch"
                                        value={searchTerm}
                                        onChange={(e) => {
                                            setSearchTerm(e.target.value);
                                            filterTexts(e.target.value);
                                        }}
                                        disabled={canMessageTCPA === false && isFeature875Enabled === true}
                                    />
                                    <div className="innerWrapper">
                                        {filteredTexts.map((text, index) => (
                                            <div className="leadInfoLine" key={`texttemplate${index}`}>
                                                <span className="dataFieldTitle">{text.name}</span>
                                                <span className="leadData" onClick={() => addTemplate(text.content, text.images)} style={{ cursor: 'pointer' }}>Click To Use</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {showEmojiPicker && (
                        <div className="emojiWrapper" style={{
                            position: 'absolute', bottom: '50px'
                        }} >
                            <Picker
                                data={data} onEmojiSelect={handleEmojiSelect}
                            />
                        </div>
                    )}

                </div>
            )}
            {!addConvo && conversations && (
                conversations.map((convo, index) => (
                    <div className={`queueConversationWrapper ${convoDisplay === (index + 1) ? '' : 'hidden'}`} key={`coversation` + (index + 1)} style={{ height: lead.pathway ? 'calc(100% - 50px)' : '100%' }}>
                        {Object.keys(convo).map((key) => (
                            <span key={`anotherConvo${key}`}>
                                <div className="conversationTitle">
                                    {formatConvoId(key)}
                                    {conversations.length > 0 && addConvo === false && (
                                        <div className="addNewConvo" onClick={() => toggleNewConvo()}>+ Convo</div>
                                    )}
                                </div>

                                <div className="queueConversationInnerWrapper" style={{ height: `calc(100% - ${textBoxHeight}px` }}>
                                    {convo[key].map((message, index) => (
                                        message.pending ? (
                                            <div key={`specConfo${index}`} className={`messageWrapper messagefrom`}>
                                                {!!msgTimestampTracker[`${key}-${message.time}`] && (
                                                    <div style={{margin: '0 5px'}}>{unixTommddhia(message.time, agentInfo.timezone)}</div>
                                                )}
                                                <div onDoubleClick={ _ => updateMsgTimestampTracker(message.time, key)} className={`convoBubble backgroundto`}>
                                                    {message.image && message.image != 0 && (
                                                        <div className="sentImageWrapper">
                                                            <img src={message.image} alt="Preview" className="sendingImage" />
                                                        </div>
                                                    )}
                                                    {message.message.split('\n').map((line, i) => (
                                                        <React.Fragment key={i}>
                                                            {line}
                                                            <br />
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            </div>
                                        ) : (
                                            <div key={`specConfo${index}`} className={`messageWrapper message${message.direction}`}>
                                                {!!msgTimestampTracker[`${key}-${message.time}`] && (
                                                    <div style={{margin: '0 5px'}}>{unixTommddhia(message.time, agentInfo.timezone)}</div>
                                                )}
                                                <div onDoubleClick={ _ => updateMsgTimestampTracker(message.time, key)} className={`convoBubble background${message.direction}`}>
                                                    {message.image && message.image != 0 && (
                                                        <div className="sentImageWrapper">
                                                            <img src={message.image} alt="Preview" className="sendingImage" />
                                                        </div>
                                                    )}
                                                    {message.message.split('\n').map((line, i) => (
                                                        <React.Fragment key={i}>
                                                            {line}
                                                            <br />
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            </div>
                                        )

                                    ))}
                                </div>
                            </span>
                        ))}
                        {lead.pathway && (
                            <div className="pathSuggestion">
                                <div className="suggestionHolder">
                                    <div className="suggestion" onClick={() => sendSuggestion(lead.convoFlowContent)}><span className="fontello" style={{ marginTop: '-7px', marginRight: '10px' }}><Icon name={'q-lightning-bolt-fast'} size={24} className="suggestionIcon" adjust="fontello" /></span>{lead.convoFlowName}</div>
                                </div>
                            </div>
                        )}
                        <div className="sendingImageWrapper">
                            {attachedImage && (
                                <div className="innerImageWrapper">
                                    <div className="removeImage" onClick={() => handleRemoveImage()}>x</div>
                                    <img src={attachedImage} alt="Preview" className="sendingImage" />
                                </div>
                            )}
                        </div>

                        {parseInt(expiration) > 0 || !expiration ? (
                            <div className="sendBar">
                                <div className="inputBarWrapper">
                                    <TextInputBar {...textInpurBarProps} />
                                </div>
                                <div className={`sendButton ${(canMessageTCPA === false && isFeature875Enabled === true) ? 'disabled' : ''}`} onClick={() => sendText()}><span className="fontello" style={{ marginTop: '-7px' }}><Icon name={'send-q'} height='38' width='30' className="" adjust="fontello" /></span></div>
                                <div className="sendBarOptions">
                                    <div className={`optionButton ${(canMessageTCPA === false && isFeature875Enabled === true) ? 'disabled' : ''}`}><span style={{ fontSize: '30px' }} onClick={() => handleEmojiPicker()}>&#9786;</span></div>
                                    <div className={`optionButton ${(canMessageTCPA === false && isFeature875Enabled === true) ? 'disabled' : ''}`} onClick={handleImageClick}><span className="fontello" style={{ marginTop: '-7px' }}><Icon name={'image-q'} height='38' width='30' className="" adjust="fontello" /></span>
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            onChange={handleFileUpload}
                                            disabled={canMessageTCPA === false && isFeature875Enabled === true}
                                        />
                                    </div>
                                    <div className={`optionButton ${(canMessageTCPA === false && isFeature875Enabled === true) ? 'disabled' : ''}`}><span className="fontello" style={{ marginTop: '-7px' }}><Icon name={'clock-q'} height='38' width='30' className="" adjust="fontello" /></span></div>
                                    <div className={`optionButton ${(canMessageTCPA === false && isFeature875Enabled === true) ? 'disabled' : ''}`} onClick={() => openvars()}><span className="fontello" style={{ marginTop: '-7px' }}><Icon name={'q-brackets-plus'} height='38' width='30' className="" adjust="fontello" /></span></div>
                                    {variables && (
                                        <div className="variableWindow">
                                            <div className="innerWrapper">
                                                {keysWithValue.map((variable, index) => (
                                                    <div className="leadInfoLine" key={`variable${index}`}><span className="dataFieldTitle">{variable.key.replace('_', ' ')}</span><span className="leadData" onClick={() => handleClick(variable.value, variable.key)} style={{ cursor: 'pointer' }}>Click To Use</span></div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    <div className={`optionButton ${(canMessageTCPA === false && isFeature875Enabled === true) ? 'disabled' : ''}`} onClick={() => openTemplates()}><span className="fontello" style={{ marginTop: '-7px' }}><Icon name={'file-text-q'} height='38' width='30' className="" adjust="fontello" /></span></div>
                                    {textsTemplates && (
                                        <div className="variableWindow">
                                            <input
                                                type="text"
                                                placeholder="Search templates..."
                                                className="templateSearch"
                                                value={searchTerm}
                                                onChange={(e) => {
                                                    setSearchTerm(e.target.value);
                                                    filterTexts(e.target.value);
                                                }}
                                                disabled={canMessageTCPA === false && isFeature875Enabled === true}
                                            />
                                            <div className="innerWrapper">
                                                {filteredTexts.map((text, index) => (
                                                    <div className="leadInfoLine" key={`texttemplate${index}`}>
                                                        <span className="dataFieldTitle">{text.name}</span>
                                                        <span className="leadData" onClick={() => addTemplate(text.content, text.images)} style={{ cursor: 'pointer' }}>Click To Use</span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : expiration > -1209600 ? (
                            <div className="expiredText">You can no longer text this lead. To resume messaging, call them and ask them to text you back to "continue working" together. You have {Math.round((expiration + 1209600) / 86400)} days left to do this before texting is permanently disabled</div>
                        ) : (
                            <div className="expiredText">Your ability to message this lead has been removed.</div>
                        )}
                        {showEmojiPicker && (
                            <div className="emojiWrapper" style={{
                                position: 'absolute', bottom: '50px'
                            }} >
                                <Picker
                                    data={data} onEmojiSelect={handleEmojiSelect}
                                />
                            </div>
                        )}
                    </div>
                ))
            )}

            {canMessageTCPA === false && isFeature875Enabled === true && (
                <>
                    <div className="queueConversationWrapper" style={{ height: lead.pathway ? 'calc(100% - 50px)' : '100%' }}>
                        <div className="conversationTitle">
                            <span className="fromNumWrapper">
                                <select className="numberSelectBox" value={fromNumber} onChange={handleFromChange}>
                                    {textingNumbers && (
                                        textingNumbers.map((number, index) => (
                                            <option key={`textingNumber${index}`} value={number.number}>{number.friendlyName}</option>
                                        ))
                                    )}
                                </select>
                            </span>
                            <span style={{ padding: '0px 5px' }}>&</span>
                            <span className="toNumWrapper">
                                <select className="numberSelectBox" value={toNumber} onChange={handleToChange}>
                                    {theirNumbers && (
                                        theirNumbers.map((number, index) => (
                                            <option key={`theirNumber${index}`}>{formatPhoneNumber(number.toString())}</option>
                                        ))
                                    )}
                                </select>
                            </span>
                        </div>
                        <div className="queueConversationInnerWrapper" style={{ height: `calc(100% - ${textBoxHeight}px` }}>
                        {newConvo && newConvo.map((message, index) => (
                            <div className={`messageWrapper message${message.direction}`} key={`newConvo${index}`}>
                                {!!msgTimestampTracker[`NEW-${message.time}`] && (
                                    <div style={{margin: '0 5px'}}>{unixTommddhia(message.time, agentInfo.timezone)}</div>
                                )}
                                <div onDoubleClick={ _ => updateMsgTimestampTracker(message.time, 'NEW')} className={`convoBubble background${message.direction}`}>
                                    {message.image && message.image != 0 && (
                                        <div className="sentImageWrapper">
                                            <img src={message.image} alt="Preview" className="sendingImage"/>
                                        </div>
                                    )}
                                    {message.message.split('\n').map((line, i) => (
                                        <React.Fragment key={i}>
                                            {line}
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                        ))}
                        </div>
                        <div className="sendingImageWrapper">
                            {attachedImage && (
                                <div className="innerImageWrapper">
                                    <div className="removeImage" onClick={() => handleRemoveImage()}>x</div>
                                    <img src={attachedImage} alt="Preview" className="sendingImage" />
                                </div>
                            )}
                        </div>
                        {isFeature875Enabled && showTCPAToolTipTextBox && (
                            // the event listeners are needed here to prevent the tooltip from flickering
                            // when hovering over it
                            <div onMouseEnter={() => setShowTCPAToolTipTextBox(true)} onMouseLeave={() => setShowTCPAToolTipTextBox(false)}>
                                <Tooltip
                                    title='Ready to send your first text?'
                                    body={`Click below to send a friendly, TCPA-compliant introductory message explaining why you're reaching out, and giving the client an easy option to opt out if they prefer.`}
                                />
                            </div>
                        )}
                        {parseInt(expiration) > 0 || !expiration ? (
                            <div className="sendBar" onMouseEnter={() => setShowTCPAToolTipTextBox(true)} onMouseLeave={() => setShowTCPAToolTipTextBox(false)}>
                                <div className="inputBarWrapper">
                                    <TextInputBar {...textInpurBarProps} />
                                </div>
                                <div className={`sendButton ${(canMessageTCPA === false && isFeature875Enabled === true) ? 'disabled' : ''}`} onClick={() => sendText()}><span className="fontello" style={{ marginTop: '-7px' }}><Icon name={'send-q'} height='38' width='30' className="" adjust="fontello" /></span></div>
                                <div className="sendBarOptions">
                                    <div className={`optionButton ${(canMessageTCPA === false && isFeature875Enabled === true) ? 'disabled' : ''}`}>
                                        <span style={{ fontSize: '30px' }} onClick={() => handleEmojiPicker()}>&#9786;</span>
                                    </div>
                                    <div className={`optionButton ${(canMessageTCPA === false && isFeature875Enabled === true) ? 'disabled' : ''}`} onClick={handleImageClick}>
                                        <span className="fontello" style={{ marginTop: '-7px' }}><Icon name={'image-q'} height='38' width='30' className="" adjust="fontello" /></span>
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            onChange={handleFileUpload}
                                            disabled={!canMessageTCPA}
                                        />
                                    </div>
                                    <div className={`optionButton ${(canMessageTCPA === false && isFeature875Enabled === true) ? 'disabled' : ''}`}>
                                        <span className="fontello" style={{ marginTop: '-7px' }}><Icon name={'clock-q'} height='38' width='30' className="" adjust="fontello" /></span>
                                    </div>
                                    <div className={`optionButton ${(canMessageTCPA === false && isFeature875Enabled === true) ? 'disabled' : ''}`} onClick={() => openvars()}>
                                        <span className="fontello" style={{ marginTop: '-7px' }}><Icon name={'q-brackets-plus'} height='38' width='30' className="" adjust="fontello" /></span>
                                    </div>
                                    {variables && (
                                        <div className="variableWindow">
                                            <div className="innerWrapper">
                                                {keysWithValue.map((variable, index) => (
                                                    <div className="leadInfoLine" key={`variable${index}`}>
                                                        <span className="dataFieldTitle">{variable.key.replace('_', ' ')}</span>
                                                        <span className="leadData" onClick={() => handleClick(variable.value, variable.key)} style={{ cursor: 'pointer' }}>Click To Use</span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    <div className={`optionButton ${(canMessageTCPA === false && isFeature875Enabled === true) ? 'disabled' : ''}`} onClick={() => openTemplates()}>
                                        <span className="fontello" style={{ marginTop: '-7px' }}><Icon name={'file-text-q'} height='38' width='30' className="" adjust="fontello" /></span>
                                    </div>
                                    {textsTemplates && (
                                        <div className="variableWindow">
                                            <input
                                                type="text"
                                                placeholder="Search templates..."
                                                className="templateSearch"
                                                value={searchTerm}
                                                onChange={(e) => {
                                                    setSearchTerm(e.target.value);
                                                    filterTexts(e.target.value);
                                                }}
                                                disabled={!canMessageTCPA}
                                            />
                                            <div className="innerWrapper">
                                                {filteredTexts.map((text, index) => (
                                                    <div className="leadInfoLine" key={`texttemplate${index}`}>
                                                        <span className="dataFieldTitle">{text.name}</span>
                                                        <span className="leadData" onClick={() => addTemplate(text.content, text.images)} style={{ cursor: 'pointer' }}>Click To Use</span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : expiration > -1209600 ? (
                            <div className="expiredText">You can no longer message this lead. You have {Math.round((expiration + 1209600) / 86400)} days left before texting is permanently disabled</div>
                        ) : (
                            <div className="expiredText">You can no longer message this lead</div>
                        )}
                        {showEmojiPicker && (
                            <div className="emojiWrapper" style={{
                                position: 'absolute', bottom: '50px'
                            }} >
                                <Picker
                                    data={data} onEmojiSelect={handleEmojiSelect}
                                />
                            </div>
                        )}

                        <div className='solidQuilityActiveButton' onClick={() => sendTCPAIntroText()} style={{ width: 'max-content', padding: '0px 24px', borderRadius: '6px', display: 'flex', alignItems: 'center', gap: '20px', cursor: 'pointer' }}>
                            Send Introductory Text Message
                            <span className="fontello" style={{ marginTop: '-7px' }}>
                                <Icon name={'send-q'} height='38' width='30' className="" adjust="fontello" />
                            </span>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
export default QueueConvo;