import React, {useState} from 'react';
import Icon from '../../../assets/icons/icon';


function Dials({details, contacts}) {
    const [open, setOpen] = useState(false);
    let calls=0;
    const openUp=()=>{
        setOpen((prevOpen) => !prevOpen);
    }
    function formatToMonthDay(dateString) {
        const originalDate = new Date(dateString);

        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        const formattedDate = originalDate.toLocaleDateString('en-US', options);

        return formattedDate;
    }
    details.map((detail, index) => (
        calls=calls + parseInt(detail.callsMade)
    ))
    return (

            <div className="quilityNestedBlockWrapper" onClick={(event) => { event.stopPropagation();openUp();}}>
                <div className='quilityNestedBlockHeader'>
                    <span className="quilityParentBlockTitle" style={{width: '170px'}}>Dials and Contacts</span>
                    <span style={{marginLeft:'20px'}}>
                        <span className="typeBlock">{details.length} Active Day{details.length > 1 && 's'}</span>
                        <span className="typeBlock">{calls} Calls</span>
                        <span className="typeBlock">{contacts ? contacts : 0} Contact{contacts > 1 || contacts < 1 ? 's': ''}</span>

                    </span>
                    <span className={`quilityBlockIcon ${open ? 'rotate':''}`} style={{marginTop: '-5px', transition:'all .5s'}}><span className="fontello"><Icon name={'q-up'} size={20} className="closeIcon" adjust="fontello" /></span></span>
                </div>

                <div className={`detailWrapper ${open ? 'openUp' : ''}`}>
                    {details.map((detail, index) => (
                        <div className="detailItem" key={"callback"+index}>
                            <span className="detailDialDate">{formatToMonthDay(detail.dialDate)}</span>
                            <span className="detailDialCount">Dials: {detail.callsMade}</span>
                            <span className="detailDialCount">Contacts: {detail.contacts}</span>
                        </div>
                    ))}
                </div>
            </div>

    );
}

export default Dials;