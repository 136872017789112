import React, { useState, useEffect } from "react";
import '../../css/templates.css';
import AddStatus from './addStatus';
import Warning from '../warning';
import { fetchWithDefaults } from '../../config/fetchDefault';
import { useSelector } from 'react-redux';


function LeadStatus({ data }) {
    const agentInfo=useSelector((state) => state.agent.userData);
    const workflows=useSelector((state) => state.workflows.workflows);
    const reminders=useSelector((state) => state.reminders.reminders);
    const [editingTemplate, setEditTemplate] = useState(false);
    const [statuses, setStatuses] = useState(data[0]);
    const [addScript, setAddScript] = useState(false);
    const [editingItemId, setEditingItemId] = useState(null);
    const [newTemplate, setNewTemplate] = useState(null);
    const [warning, setWarning] = useState(false);
    const [editedText, setEditedText] = useState({});
    const [editedIndex, setEditedIndex] = useState(null);
    const agentId=agentInfo.agentId;
    const teamId=agentInfo.teamId;

    const cancel = () => {
        setWarning(false);
    };
    const confirm = async (id, index) => {
        try {
            const requestOptions = {
                method: 'DELETE'
            };
          
            const response = await fetchWithDefaults(`/deleteStatus?statusId=${warning}`, requestOptions);
        
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const jsonData = await response.json();
         
            console.log(jsonData);
            if(jsonData.status===true){
                const newArray = [...statuses];

                // Find the index of the object with the specified ID
                const indexToRemove = newArray.findIndex(obj => obj.id === warning);

                // Remove the object at the found index
                if (indexToRemove !== -1) {
                    newArray.splice(indexToRemove, 1);
                    setStatuses(newArray);
                }
                setWarning(false);
            }
        } 
        catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const addAScript = () => {
        setAddScript(!addScript);
    };
    const handleInputChange = (e, index) => {
        setEditedText({ ...editedText, [index]: e.target.value });
    };
    const handleEditName = (index, text) => {
        setEditedIndex(index);
        //setEditedText({ [index]: text });
        setEditedText({ ...editedText, [index]: text });
    };
    const setStatusOption = (contentType, content, statusId) => {
        //setCurrentStatusReminder(content);
        updateTemplate(statusId, contentType, content);
    };
    const setStatusWorkflow = (contentType, content, statusId) => {
        //setCurrentStatusReminder(content);
        updateTemplate(statusId, contentType, content);
    };
    const removeItem = (id,index) => {
        setWarning(id);
    };
    
    const updateTemplate = async (status, type, content) => {
        setEditedIndex(null);
        try {
            const response = await fetch(
                `${process.env.REACT_APP_FUNNEL_HOSTNAME}updateStatus?status=${status}&content=${content}&type=${type}`,
                {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json", 
                    },
                }
            );
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const jsonData = await response.json();
        } 
        catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    return (
        <div className="outerWrapper">
            {warning && 
                <Warning title={"Delete Status"} cancel={cancel} confirm={confirm} message={"Are you sure you want to remove this status?"} buttons={[{name:'Don\'t Delete',action:'cancel'},{name:"Confirm Delete", action:'confirm'}]} />
            }
            
            <span className="outerWrapperTitle">
                <span className="outerWrapperIcon">

                </span>
                Statuses
            </span>
            <div
                className="mainBlockOuterWrapper"
                style={{ justifyContent: "space-around" }}
            >

            {addScript ? (
               <AddStatus teamId={teamId} agentId={agentId} addATemplate={addAScript} setNewTemplate={setNewTemplate} type={'Status'}/>

            ):(
            null
            )}
            <div className={`frontPageQuilityParentBlockWrapper ${addScript ? 'closeMainBlock' : editingTemplate ? 'closeMainBlock' : ''}`} style={{width:'70%'}}>
                    <div className="quilityParentBlockHeader">
                        <div className="quilityParentBlockTitle">Status</div>

                    </div>
                    <div className="quilityParentBlockContent">
                        {newTemplate === null ? '' : (
                            <div className={`quilityNestedBlockWrapper  ${editingItemId === newTemplate.id ? 'focusBigBlock' : ''}`} key={'temp'} style={{marginTop: '25px'}}>
                                <div className="removeItem" onClick={()=> removeItem(newTemplate.id, 'temp')}>X</div>
                                <div className="quilityNestedBlockHeader">
                                    <div className="quilityParentBlockTitle">
                                        {newTemplate.name}
                                    </div>
                                </div>
                                <div className="quilityNestedBlockContent">
                                    <div className="quilityDataLine">
                                        <span className="itemTitle">Status Name: </span>
                                        {newTemplate.name}
                                    </div>
                                    <div className="quilityDataLine">
                                        <span className="itemTitle">Type: </span>
                                        {newTemplate.type}
                                    </div>
                                    <div className="quilityDataLine">
                                        <span className="itemTitle">Date Created: </span>
                                        Just Now
                                    </div>
                                </div>
                            </div>
                        )}
                        {statuses.map((item, index) => (    
                            <div className={`quilityNestedBlockWrapper  ${editingItemId === item.id ? 'focusBigBlock' : ''}`} key={`statusBlock${index}`} style={{marginTop: '25px'}}>
                                <div className="removeItem" onClick={()=> removeItem(item.id, index)}>X</div>
                                <div className="quilityNestedBlockHeader">
                                    <div className="quilityParentBlockTitle">
                                        {editedText[index] ?? item.name}
                                    </div>
                                </div>
                                <div className="quilityNestedBlockContent">
                                    <div className="quilityDataLine">
                                        <span className="itemTitle" style={{display: 'inline-block',width: '230px'}}>Status Name: </span>
                                        {editedIndex === index ? (
                                            <input
                                                type="text"
                                                className="standardInputBox"
                                                value={editedText[index] || item.name}
                                                onChange={(e) => handleInputChange(e, index)}
                                                onBlur={() => setStatusOption('name',editedText[index], item.id)}
                                                
                                                autoFocus
                                            />
                                            ) : (
                                            <>
                                                <span style={{ marginLeft: "5px" }} onClick={() =>
                                                    handleEditName(index, editedText[index])
                                                }>
                                                {editedText[index] || item.name}
                                                </span>
                                                <span
                                                className="hoverIcon"
                                                onClick={() =>
                                                    handleEditName(index, editedText[index])
                                                }
                                                >
                                                {/*pencil*/}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                    <div className="quilityDataLine">
                                        <span className="itemTitle" style={{display: 'inline-block',width: '230px'}}>Makes Lead Type: </span>
                                        <select
                                            className="standardSelectBox"
                                            onChange={(e) => setStatusOption('type', e.target.value, item.id)}
                                        >
                                            {['active', 'new', 'dead', 'client'].map((option) => (
                                            <option key={option} value={option} selected={item.type === option ? true : false}>
                                                {option.charAt(0).toUpperCase() + option.slice(1)}
                                            </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="quilityDataLine">
                                        <span className="itemTitle" style={{display: 'inline-block',width: '230px'}}>Is Status a Contact?: </span>
                                        {item.contact==='0' || item.contact===null ? <select className="standardSelectBox" onChange={(e) => setStatusOption('contact', e.target.value, item.id)}><option value='1'>Contact</option><option value='0' selected>Not a Contact</option></select>:<select className="standardSelectBox" onChange={(e) => setStatusOption('contact', e.target.value, item.id)}><option value='1' selected>Contact</option><option value='0'>Not a Contact</option></select>}
                                    </div>
                                    <div className="quilityDataLine">
                                        <span className="itemTitle" style={{display: 'inline-block',width: '230px'}}>Is Status an Appointment?: </span>
                                        {item.appointment==='0' || item.appointment===null ? <select className="standardSelectBox" onChange={(e) => setStatusOption('appointment', e.target.value, item.id)}><option value='1'>Appointment</option><option value='0' selected>Not an Appointment</option></select>:<select className="standardSelectBox" onChange={(e) => setStatusOption('appointment', e.target.value, item.id)}><option value='1' selected>Appointment</option><option value='0'>Not an Appointment</option></select>}
                                    </div>

                                    <div className="quilityDataLine">
                                        <span className="itemTitle" style={{display: 'inline-block',width: '230px'}}>Workflow Attached: </span>
                                        
                                        <select 
                                            className="standardSelectBox" 
                                            onChange={(e) => setStatusOption('workflow', e.target.value, item.id)}
                                        >
                                            <option value="none">No Workflow</option>
                                            {workflows.map((items, indexs) => (
                                                items.id===item.workflow ? <option key={`option2${indexs}`} value={items.id} selected>{items.name}</option> : <option key={indexs} value={items.id}>{items.name}</option>
                                                
                                            ))}
                                        </select>
                                    </div>
                                    
                                    
                                    <div className="quilityDataLine">
                                        <span className="itemTitle" style={{display: 'inline-block',width: '230px'}}>Reminder Attached: </span>
                                        
                                        <select 
                                            className="standardSelectBox" 
                                            onChange={(e) => setStatusOption('reminder', e.target.value, item.id)}
                                        >
                                            <option value="none">No Reminder</option>
                                            {reminders.map((items, indexs) => (
                                                items.id===item.reminder ? <option key={`indexs3${indexs}`} value={items.id} selected>{items.name}</option> : <option key={indexs} value={items.id}>{items.name}</option>
                                                
                                            ))}
                                        </select>
                                    </div>
                                
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="blockFooter">
                        <div
                            className="buttonWrapper"
                            style={{ marginTop: " 5px", marginLeft: "10px" }}
                        >
                            <div className="dataButtonBlock">Statuses: {statuses.length}</div>
                            <div className="actionButtonBlock">
                                <div className="accentButton solidQuilityActiveButton" onClick={()=>addAScript()}>
                                +  New Status
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

    );
}

export default LeadStatus;

