import React, { useState, useEffect } from 'react';
import '../../css/phone.css';
import { useSelector } from 'react-redux';
import { recording } from '../../utilities/callFunctions';
import Icon from '../../assets/icons/icon';
import { formatPhoneNumber, timestampConvert } from '../../utilities/dashFunction';


function SoftPhone({ callStatus, setCallStatus, openLead, callerId}) {
    const call=useSelector((state) => state.call.call);
    const agentInfo=useSelector((state) => state.agent.userData);
    const [dragging, setDragging] = useState(false);
    const [recordingStatus, setRecordingStatus] = useState(true);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [offset, setOffset] = useState({ x: 0, y: 0 });
    const [openKeypad, setOpenKeypad] = useState(false);
    const handleMouseDown = (e) => {
        const offsetX = e.clientX - position.x;
        const offsetY = e.clientY - position.y;
    
        setDragging(true);
        setOffset({ x: offsetX, y: offsetY });
    };
    const handleKeypad=()=>{
        setOpenKeypad(!openKeypad);
    }
    const handleMouseMove = (e) => {
        e.preventDefault();
        if (!dragging) return;
    
        const x = e.clientX - offset.x;
        const y = e.clientY - offset.y;
    
        setPosition({ x, y });
    };
  
    const handleMouseUp = () => {
        setDragging(false);
    };

    const [muted, setMute] = useState(false);

    const accept= async ()=>{
        call.accept();
        setCallStatus('pending');
    }
    const ignore= async ()=>{
        call.ignore();
        setCallStatus(call.status());
    }
    const mute= async ()=>{
        setMute((prevMute) => !prevMute);
        if(muted===true){
            call.mute(false);
        }
        else{
            call.mute(true);
        }
    }
    const hangup= async ()=>{
        call.disconnect()
        setCallStatus(call.status());
    }
    useEffect(() => {
        call.on('disconnect', call => {
            setCallStatus(call.status());
        });
        call.on('accept', call => {
            setCallStatus(call.status());
            console.log(call.status());
          });
    },[])
    useEffect(() => {
        if (dragging) {
          document.addEventListener('mousemove', handleMouseMove);
          document.addEventListener('mouseup', handleMouseUp);
    
          return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
          };
        }
      }, [dragging, handleMouseMove, handleMouseUp]);
    const sendDigit=(digit)=>{
        call.sendDigits(digit)
    }
    const handleRecording=()=>{
        const callSid = call.parameters.CallSid;
        setRecordingStatus(!recordingStatus)
        recording(callSid,recordingStatus,agentInfo.agentId,callerId.lead_id);
    }
    return (
        <div className="softPhoneWrapper"
            style={{
                position: 'absolute',
                left: position.x,
                top: position.y,
            }}
            draggable
            onMouseDown={handleMouseDown}
        >
            <div className="wrapperTopBar">
                <div className="wrapperTopBarTitle">{callStatus && callStatus==='connecting' ? 'Outgoing Call' : callStatus && callStatus==='open' ? 'Current Call' :'Incoming Call'} </div>
            </div>
            <div className="softPhoneLine" style={{color: 'black'}}>
                <span className="lineTitle">Call With: </span>
                <span className="callWith">{callerId.name}</span>
                <span className="viewLeadPhone" onClick={() => openLead(callerId.lead_id)}>View Lead</span>
            </div>
            <div className="softPhoneLine" style={{color: 'black'}}>
                <span className="lineTitle">Call Status: </span>
                {callStatus && callStatus==='open' ? (
                    <span className="callStatus"><span style={{color:'#03a903',fontWeight:'700'}}>Connected</span></span>
                ) : callStatus && callStatus==='pending' ? (
                    <span className="callStatus"><span style={{color:'#bf7f00',fontWeight:'700'}}>Ringing...</span></span>
                ) : callStatus && callStatus==='connecting' ? (
                    <span className="callStatus"><span style={{color:'#bf7f00',fontWeight:'700'}}>Ringing...</span></span>
                ) : callStatus && callStatus==='closed' && (
                    <span className="callStatus"><span style={{color:'#bf0000',fontWeight:'700'}}>Disconnected</span></span>
                )}
                
            </div>
            <div className="softPhoneLine" style={{color: 'black'}}>
                <span className="lineTitle">Number Used: </span>
                <span className="callStatus">{callerId.outbound_number ? formatPhoneNumber(callerId.outbound_number) : 'NA'}</span>
            </div>
            <div className="softPhoneLine" style={{color: 'black'}}>
                <span className="lineTitle">Last Contact: </span>
                <span className="callStatus">{callerId.last_contact ? timestampConvert(callerId.last_contact) : 'NA'}</span>
            </div>
            
            <div className="softPhoneButtonWrapper">
                {
                    callStatus ==='pending'? (
                        <>
                            <span className="softPhoneButton reject" onClick={ignore}>
                                Reject<span className="buttonIcon"><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'q-phone-call-end'} size={20} className="" height='35' adjust="fontello" /></span></span>
                            </span>
                            <span className="softPhoneButton answer" onClick={accept}>
                                Answer<span className="buttonIcon"><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'q-phone-call-incoming'} size={18} className="" height='35' adjust="fontello" /></span></span>
                            </span>
                        </>
                    ) : callStatus==='connecting' ? (
                        <>
                            <span className="softPhoneButton softPhoneButtonStyle" onClick={()=>handleKeypad()}>
                                Keypad<span className="buttonIcon"><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'q-keypad'} size={18} className="" height='35' adjust="fontello" /></span></span>
                            </span>
                            <span className="softPhoneButton softPhoneButtonStyle" onClick={hangup}>
                                Record<span className="buttonIcon"><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'q-microphone-on'} size={18} className="" height='35' adjust="fontello" /></span></span>
                            </span>
                            <span className={`softPhoneButton softPhoneButtonStyle ${muted ? 'activePhoneButton' : ''}`} onClick={mute}>
                                Mute<span className="buttonIcon"><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'q-volume-2'} size={18} className="" height='35' adjust="fontello" /></span></span>
                            </span>
                            <span className="softPhoneButton reject" onClick={hangup}>
                                Hangup<span className="buttonIcon"><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'q-phone-call-end'} size={20} className="" height='35' adjust="fontello" /></span></span>
                            </span>
                        </>
                    ) : callStatus === 'open' ? (
                        <>
                            <span className="softPhoneButton softPhoneButtonStyle" onClick={()=>handleKeypad()}>
                                Keypad<span className="buttonIcon"><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'q-keypad'} size={18} className="" height='35' adjust="fontello" /></span></span>
                            </span>
                            <span className={`softPhoneButton softPhoneButtonStyle ${recordingStatus ? '': 'activePhoneButton'}`} onClick={handleRecording}>
                                Record<span className="buttonIcon"><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'q-microphone-on'} size={18} className="" height='35' adjust="fontello" /></span></span>
                            </span>
                            <span className={`softPhoneButton softPhoneButtonStyle ${muted ? 'activePhoneButton' : ''}`} onClick={mute}>
                                Mute<span className="buttonIcon"><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'q-volume-2'} size={18} className="" height='35' adjust="fontello" /></span></span>
                            </span>
                            <span className="softPhoneButton reject" onClick={hangup}>
                                Hangup<span className="buttonIcon"><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'q-phone-call-end'} size={20} className="" height='35' adjust="fontello" /></span></span>
                            </span>
                        </>
                    ) : 
                    <>
                        <span className="softPhoneButton reject" onClick={ignore}>
                            Reject<span className="buttonIcon"><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'q-phone-call-end'} size={20} className="" height='35' adjust="fontello" /></span></span>
                        </span>
                        <span className="softPhoneButton answer" onClick={accept}>
                            Answer<span className="buttonIcon"><span className="fontello" style={{marginTop: '-7px'}}><Icon name={'q-phone-call-incoming'} size={18} className="" height='35' adjust="fontello" /></span></span>
                        </span>
                    </>
                }
                {openKeypad &&(
                    <div className="keypadHolder" style={{marginTop:'70px'}}>
                        <div className="keypadHeader">
                            Keypad
                        </div>
                        <div className="keypadContent">
                            <div className="keypadNumber" onClick={()=>sendDigit('1')}><span className="innerKeypad">1</span></div>
                            <div className="keypadNumber" onClick={()=>sendDigit('2')}><span className="innerKeypad">2</span></div>
                            <div className="keypadNumber" onClick={()=>sendDigit('3')}><span className="innerKeypad">3</span></div>
                            <div className="keypadNumber" onClick={()=>sendDigit('4')}><span className="innerKeypad">4</span></div>
                            <div className="keypadNumber" onClick={()=>sendDigit('5')}><span className="innerKeypad">5</span></div>
                            <div className="keypadNumber" onClick={()=>sendDigit('6')}><span className="innerKeypad">6</span></div>
                            <div className="keypadNumber" onClick={()=>sendDigit('7')}><span className="innerKeypad">7</span></div>
                            <div className="keypadNumber" onClick={()=>sendDigit('8')}><span className="innerKeypad">8</span></div>
                            <div className="keypadNumber" onClick={()=>sendDigit('9')}><span className="innerKeypad">9</span></div>
                            <div className="keypadNumber" onClick={()=>sendDigit('#')}><span className="innerKeypad">#</span></div>
                            <div className="keypadNumber" onClick={()=>sendDigit('0')}><span className="innerKeypad">0</span></div>
                            <div className="keypadNumber" onClick={()=>sendDigit('*')}><span className="innerKeypad">*</span></div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );

}

export default SoftPhone;